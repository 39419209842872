import {useTranslation} from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import {asCurrency, round} from '../../utils/formatter';
import {Observer} from 'mobx-react';
import ProductCheckout from '../ProductCheckout';
import useScreenWidth from '../../hooks/useScreenWidth';
import {Dispatch, SetStateAction, useContext, useEffect} from 'react';
import {Button, Drawer} from 'antd';
import {CloseIcon, CloseIconWhite} from '../CustomIcons';
import {Link} from 'react-router-dom';
import {autorun} from 'mobx';

interface CartDropdownProps {
  cartOpen: boolean;
  setCartOpen: Dispatch<SetStateAction<boolean>>;
}

const CartDropdown = ({cartOpen, setCartOpen}: CartDropdownProps) => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const screenWidth = useScreenWidth();
  //
  const {t, i18n} = useTranslation();

  //
  const total = storeCommon.cart.reduce(
    (a, b) => a + (b.total_discounted || b.total),
    0,
  );
  const discount = storeCommon.cart.reduce(
    (a, b) => a + (b.total_discounted ? b.total - b.total_discounted : 0),
    0,
  );

  //
  useEffect(() => {
    autorun(() => {
      if (!storeCommon.cart.length) {
        setCartOpen(false);
      }
    });
  }, []);

  return (
    <Observer>
      {() => (
        <Drawer
          placement={i18n.language === 'he' ? 'left' : 'right'}
          width={screenWidth > 640 ? '30%' : '100%'}
          open={cartOpen}
          closeIcon={screenWidth > 640 ? <CloseIconWhite /> : <CloseIcon />}
          onClose={() => setCartOpen(false)}
          bodyStyle={{
            padding: 15,
          }}
          className={'ic-template ic-cartdropdown__drawer'}
          title={
            <div className='ic-cartdropdown__header'>
              <span className='ic-cartdropdown__header-title'>
                {t('My cart')}
              </span>
              <span className='ic-cartdropdown__header-quantity'>
                {t('{count, plural, =1{# item} other{# items}}', {
                  count: storeCommon.cart.length,
                })}
              </span>
            </div>
          }
          footer={
            !storeCommon.cart.length ? (
              false
            ) : (
              <div className='ic-cartdropdown__total'>
                {!!discount && (
                  <>
                    <div className='ic-cartdropdown__total-title'>
                      <span>{t('Total before discounts')}:</span>
                      <span className='ic-cartdropdown__currenc'>
                        {asCurrency(
                          round(total + discount, 2),
                          i18n.language,
                          'ILS',
                        )}
                      </span>
                    </div>
                    <div className='ic-cartdropdown__total-title'>
                      <span>{t('Discount')}:</span>
                      <span className='ic-cartdropdown__currenc'>
                        {asCurrency(-discount, i18n.language, 'ILS')}
                      </span>
                    </div>
                  </>
                )}
                <div className='ic-cartdropdown__total-title'>
                  <span>{t('Total')}:</span>
                  <span className='ic-cartdropdown__currenc'>
                    <b>{asCurrency(total, i18n.language, 'ILS')}</b>
                  </span>
                </div>

                <div className='ic-dropdownCart__buttons'>
                  <Link
                    to='/cart'
                    onClick={() => {
                      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                      setCartOpen(false);
                    }}>
                    <Button type='primary' ghost block size='large'>
                      {t('View cart')}
                    </Button>
                  </Link>
                  <Link
                    to='/checkout'
                    onClick={() => {
                      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                      setCartOpen(false);
                    }}>
                    <Button type='primary' block size='large'>
                      {t('Checkout')}
                    </Button>
                  </Link>
                </div>
              </div>
            )
          }>
          <div
            className='ic-cartdropdown ic-template ic-dropdownCart'
            style={{}}>
            <div className='ic-cartdropdown__products'>
              {storeCommon.cart.length ? (
                storeCommon.cart.map((cart, index) => (
                  <ProductCheckout cart={cart} key={index} />
                ))
              ) : (
                <>
                  <div className='ic-cartdropdown__empty'>
                    {t('Cart empty')}
                  </div>
                  <Link
                    onClick={() => {
                      setCartOpen(false);
                      window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
                    }}
                    className='ic-cartdropdown__empty-btn'
                    to='/'>
                    <Button type='primary' size='large'>
                      {t('Continue Srearching')}
                    </Button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </Drawer>
      )}
    </Observer>
  );
};

export default CartDropdown;
