import {Button, Layout, Spin} from 'antd';
import {useCallback, useContext} from 'react';
import {useEffect, useState} from 'react';
import api from '../../api/api';
import AppRouter from '../../router';
import CommonStore from '../../store/CommonStore';
import {Product, Shop, ValidationError} from '../../types';
import {useTranslation} from 'react-i18next';
import LayoutFooter from '../LayoutFooter';
import LayoutHeader from '../LayoutHeader';
import CategoriesSlider from '../CategoriesSlider';
import useScreenWidth from '../../hooks/useScreenWidth';
import {useHistory, useLocation} from 'react-router-dom';
import {getCurrentProductVariation} from '../../utils/product';
import {asCurrency, round} from '../../utils/formatter';

interface ShopInfoAnswer extends Shop {
  error?: ValidationError[];
}

interface DialogflowPayload {
  action: string;
  id: string;
  quantity: string;
}

const MainLayout = () => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const [shop, setShop] = useState<Shop | null>(null);
  //
  const width = useScreenWidth();
  //
  const location = useLocation();
  //
  const history = useHistory();
  //
  const {t, i18n} = useTranslation();

  //
  const getShopData = useCallback(async () => {
    const lang = (location.pathname || '/').slice(1);

    if (lang === 'he' || lang === 'ru' || lang === 'en') {
      localStorage.setItem('language', lang);
      i18n.changeLanguage(lang);
      history.replace('/');
    }

    const resp: ShopInfoAnswer = await api(
      'api/shop-info?expand=categories,settings,template,shipment_methods',
    );
    if (resp.error) {
      return;
    }

    storeCommon.setShop(resp);

    // localStorage.setItem('need_address', new Date().getTime().toString());

    // SET CURRENT BRANCH
    if (!localStorage.getItem('branch')) {
      storeCommon.setBranch(resp.branches[0]);
      localStorage.setItem('branch', resp.branches[0]._id);
      // localStorage.setItem('first_start', new Date().getTime().toString());
    } else {
      const branch = resp.branches.find(
        b => b._id === localStorage.getItem('branch'),
      );
      if (branch) storeCommon.setBranch(branch);
    }
    setShop(resp);

    if (!localStorage.getItem('language')) {
      localStorage.setItem('language', resp.settings.languages?.[0] || 'he');
      i18n.changeLanguage(localStorage.getItem('language') || 'he');
    }

    if (process.env.NODE_ENV === 'development') {
      var cssId = `styles-${resp.template._id}`;
      if (!document.getElementById(cssId)) {
        var head = document.getElementsByTagName('head')[0];
        var link = document.createElement('link');
        link.id = cssId;
        link.rel = 'stylesheet';
        link.type = 'text/css';
        link.href = `${process.env.REACT_APP_FRONT_ROUTE}templates/${
          // '6151b00c0277fc7a173a3342'
          resp.template._id
        }/css/style.css?v=${new Date().getTime()}`;
        // link.media = 'all';
        head.appendChild(link);
        const colors = resp.template.schemas[resp.settings.schema];
        const schema = document.createElement('style');
        schema.innerHTML = `:root { ${colors
          .map((color, index) => `--color${index + 1}: ${color}`)
          .join('; ')}; --antd-wave-shadow-color: ${colors[0]}; }`;
        head.appendChild(schema);
      }
    }
  }, [history, i18n, location.pathname, storeCommon]);

  //
  const getUserData = useCallback(async () => {
    const response = await api('account');
    if (!response.error) {
      storeCommon.setUser(response);
    } else {
      localStorage.removeItem('token');
    }
  }, [storeCommon]);

  //
  const addProductToCart = async (payload: DialogflowPayload) => {
    const products: {data: Product[]} = await api(
      `products?filter[_id]=${payload.id}`,
    );
    if (products.data.length) {
      const product = products.data[0];
      const cartProduct = getCurrentProductVariation(
        product,
        product.variants?.[0]?.values || null,
      );
      if (!cartProduct) {
        return;
      }
      storeCommon.addToCart(
        {
          product_id: cartProduct?._id,
          quantity: parseFloat(payload.quantity),
          variant: product.variants?.[0]?.values,
          product: cartProduct,
          total: 0,
        },
        cartProduct.subtract ? cartProduct.stock || 0 : 0,
      );

      const dfMessenger: any = document.querySelector('df-messenger');
      if (dfMessenger) {
        dfMessenger.renderCustomText(
          t('You bought for {total} now.', {
            total: asCurrency(
              round(
                storeCommon.cart.reduce((a, b) => a + b.total, 0),
                2,
              ),
              i18n.language,
              'ILS',
            ),
          }),
        );
      }
    }
  };

  //
  useEffect(() => {
    getShopData();

    if (localStorage.getItem('token')) {
      getUserData();
    }

    window.addEventListener('storage', e => {
      if (e.key === 'language' || e.key === 'branch') {
        setShop(null);
        getShopData();

        if (e.key === 'branch' && location.pathname.includes('checkout')) {
          history.push('/');
        }
      }
    });
  }, [getShopData, getUserData]);

  //
  useEffect(() => {
    window.scrollTo({top: 0, left: 0, behavior: 'smooth'});
  }, [location.pathname]);

  //
  useEffect(() => {
    const dfMessenger = document.querySelector('df-messenger');
    if (dfMessenger)
      dfMessenger.addEventListener('df-response-received', (event: any) => {
        // console.log(event);
        // console.log(event?.detail?.response?.queryResult?.fulfillmentMessages);
        let payload: DialogflowPayload | null = null;
        event?.detail?.response?.queryResult?.fulfillmentMessages?.forEach(
          (message: any) => {
            if (message.payload?.action) {
              payload = message.payload;
            }
          },
        );

        if (payload) {
          addProductToCart(payload);
        }
      });
  }, []);

  /**
   * 
   *if (product) {
      const cartProduct = getCurrentProductVariation(
        product,
        product.variants?.[0]?.values || null
      );
      if (!cartProduct) {
        return;
      }
      storeCommon.addToCart(
        {
          product_id: cartProduct?._id,
          quantity: q,
          variant: product.variants?.[0]?.values,
          product: cartProduct,
          total: 0,
        },
        cartProduct.subtract ? cartProduct.stock || 0 : 0
      );
      setTimeout(addToCartAnimation, 10);
      // addToCartAnimation();
    }
   */

  return !!shop ? (
    <div className={`ic-template ${shop.template._id}`}>
      <LayoutHeader />
      {width >= 640 && <CategoriesSlider />}
      <main>
        {/* <div className="ic-wrapper"> */}
        <AppRouter shop={shop} />
        {/* </div> */}
      </main>
      <LayoutFooter />
    </div>
  ) : (
    <Spin
      size='large'
      style={{
        width: '100vw',
        height: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    />
  );
};

export default MainLayout;
