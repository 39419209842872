import {
  MinusCircleOutlined,
  StopOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  DollarOutlined,
  LoadingOutlined,
  HistoryOutlined,
  CarOutlined,
  LikeOutlined,
} from '@ant-design/icons';
import Icon from '@ant-design/icons';
import {OrderStatuses} from './types/enums';

import heIcon from './assets/img/he.svg';
import ruIcon from './assets/img/ru.svg';
import enIcon from './assets/img/en.svg';

const Config = {
  orderStatuses: {
    [OrderStatuses.CLIENT_CANCELATION]: {
      value: OrderStatuses.CLIENT_CANCELATION,
      label: 'Client cancelation',
      color: 'black',
      icon: MinusCircleOutlined,
      selectable: true,
    },
    [OrderStatuses.SUSTEM_ABORTED]: {
      value: OrderStatuses.SUSTEM_ABORTED,
      label: 'System aborted',
      color: 'default',
      icon: StopOutlined,
      selectable: false,
    },
    [OrderStatuses.JUST_CREATED]: {
      value: OrderStatuses.JUST_CREATED,
      label: 'Just created',
      color: 'default',
      icon: PlusCircleOutlined,
      selectable: false,
    },
    [OrderStatuses.PRODUCT_RESERVATION]: {
      value: OrderStatuses.PRODUCT_RESERVATION,
      label: 'Product reservation',
      color: 'warning',
      icon: QuestionCircleOutlined,
      selectable: false,
    },
    [OrderStatuses.TOTAL_PAID]: {
      value: OrderStatuses.TOTAL_PAID,
      label: 'Total paid',
      color: 'error',
      icon: DollarOutlined,
      selectable: false,
    },
    [OrderStatuses.ORDER_PREPARATION]: {
      value: OrderStatuses.ORDER_PREPARATION,
      label: 'Order preparation',
      color: 'blue',
      icon: LoadingOutlined,
      selectable: true,
    },
    [OrderStatuses.WAITING_FOR_SENDING]: {
      value: OrderStatuses.WAITING_FOR_SENDING,
      label: 'Waiting for sending',
      color: 'purple',
      icon: HistoryOutlined,
      selectable: true,
    },
    [OrderStatuses.WAS_SEND]: {
      value: OrderStatuses.WAS_SEND,
      label: 'Was send',
      color: 'gold',
      icon: CarOutlined,
      selectable: true,
    },
    [OrderStatuses.CLIENT_RECIEVED]: {
      value: OrderStatuses.CLIENT_RECIEVED,
      label: 'Client recieved',
      color: 'success',
      icon: LikeOutlined,
      selectable: true,
    },
  } as {
    [key: number]: {
      value: number;
      label: string;
      color: string;
      icon: typeof Icon;
      selectable: boolean;
    };
  },

  hexTransparencies: {
    100: 'FF',
    99: 'FC',
    98: 'FA',
    97: 'F7',
    96: 'F5',
    95: 'F2',
    94: 'F0',
    93: 'ED',
    92: 'EB',
    91: 'E8',
    90: 'E6',
    89: 'E3',
    88: 'E0',
    87: 'DE',
    86: 'DB',
    85: 'D9',
    84: 'D6',
    83: 'D4',
    82: 'D1',
    81: 'CF',
    80: 'CC',
    79: 'C9',
    78: 'C7',
    77: 'C4',
    76: 'C2',
    75: 'BF',
    74: 'BD',
    73: 'BA',
    72: 'B8',
    71: 'B5',
    70: 'B3',
    69: 'B0',
    68: 'AD',
    67: 'AB',
    66: 'A8',
    65: 'A6',
    64: 'A3',
    63: 'A1',
    62: '9E',
    61: '9C',
    60: '99',
    59: '96',
    58: '94',
    57: '91',
    56: '8F',
    55: '8C',
    54: '8A',
    53: '87',
    52: '85',
    51: '82',
    50: '80',
    49: '7D',
    48: '7A',
    47: '78',
    46: '75',
    45: '73',
    44: '70',
    43: '6E',
    42: '6B',
    41: '69',
    40: '66',
    39: '63',
    38: '61',
    37: '5E',
    36: '5C',
    35: '59',
    34: '57',
    33: '54',
    32: '52',
    31: '4F',
    30: '4D',
    29: '4A',
    28: '47',
    27: '45',
    26: '42',
    25: '40',
    24: '3D',
    23: '3B',
    22: '38',
    21: '36',
    20: '33',
    19: '30',
    18: '2E',
    17: '2B',
    16: '29',
    15: '26',
    14: '24',
    13: '21',
    12: '1F',
    11: '1C',
    10: '1A',
    9: '17',
    8: '14',
    7: '12',
    6: '0F',
    5: '0D',
    4: '0A',
    3: '08',
    2: '05',
    1: '03',
    0: '00',
  } as {
    [key: number]: string;
  },

  languages: {
    he: {label: 'עברית', value: 'he', icon: heIcon},
    en: {label: 'English', value: 'en', icon: enIcon},
    ru: {label: 'Русский', value: 'ru', icon: ruIcon},
  } as {[key: string]: {label: string; value: string; icon: string}},
};

export default Config;
