import ReactDOM from 'react-dom';
import App from './App';
// import reportWebVitals from './reportWebVitals';

import i18next from 'i18next';
import {I18nextProvider, initReactI18next} from 'react-i18next';
import he from './translations/he';
import ru from './translations/ru';
import {CookiesProvider} from 'react-cookie';
import ICU from 'i18next-icu';

i18next
  .use(ICU)
  .use(initReactI18next)
  .init(
    {
      lng: localStorage.getItem('language') || 'he',
      resources: {
        he,
        ru,
      },
      fallbackLng: 'en',
    },
    () => {
      const lng = localStorage.getItem('language') || 'he';
      const htmlElement = document.querySelector('html');
      if (htmlElement) {
        htmlElement.setAttribute('lang', lng);
        htmlElement.setAttribute('dir', lng === 'he' ? 'rtl' : 'ltr');
      }
    },
  );
i18next.on('languageChanged', lng => {
  localStorage.setItem('language', lng);
  const htmlElement = document.querySelector('html');
  if (htmlElement) {
    htmlElement.setAttribute('lang', lng);
    htmlElement.setAttribute('dir', lng === 'he' ? 'rtl' : 'ltr');
  }
});

ReactDOM.render(
  <I18nextProvider i18n={i18next}>
    <CookiesProvider>
      <App />
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
