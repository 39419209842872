import { useContext, useEffect, useState } from 'react';
import CommonStore from '../../store/CommonStore';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Link } from 'react-router-dom';
// Import Swiper styles
import '../../../node_modules/swiper/swiper-bundle.css';
import CartButtonScroll from '../CartButtonScroll';
import useScreenWidth from '../../hooks/useScreenWidth';
import classNames from 'classnames';

const CategoriesSlider = () => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const [scroll, setScroll] = useState(0);
  //
  const width = useScreenWidth();

  const handleScroll = () => {
    if (window.scrollY < 400) {
      setScroll(window.scrollY);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <>
      {!!storeCommon.shop?.categories.filter((cat) => cat.promotion).length &&
        storeCommon.shop?.categories.filter((cat) => cat.promotion).length >=
          7 && (
          <div className="ic-categories-slider" style={{ overflow: 'hidden' }}>
            <Swiper
              direction="horizontal"
              className={classNames('ic-categories-slider__items', {
                active: Math.round(scroll) >= 80,
              })}
              style={{ overflow: 'visible' }}
              slidesPerView={4}
              threshold={50}
              // loop
              breakpoints={{
                640: {
                  slidesPerView: Math.min(
                    8,
                    storeCommon.shop.categories.filter((cat) => cat.promotion)
                      .length
                  ),
                },
                991: {
                  slidesPerView: Math.min(
                    10,
                    storeCommon.shop.categories.filter((cat) => cat.promotion)
                      .length
                  ),
                },
                1024: {
                  slidesPerView: Math.min(
                    12,
                    storeCommon.shop.categories.filter((cat) => cat.promotion)
                      .length
                  ),
                },
                1280: {
                  slidesPerView: Math.min(
                    14,
                    storeCommon.shop.categories.filter((cat) => cat.promotion)
                      .length
                  ),
                },
                1440: {
                  slidesPerView: Math.min(
                    14,
                    storeCommon.shop.categories.filter((cat) => cat.promotion)
                      .length
                  ),
                },
              }}
              // centeredSlides
            >
              {storeCommon.shop.categories
                .filter((cat) => cat.promotion)
                // .slice(0, 3)
                .map((cat) => (
                  <SwiperSlide
                    className="ic-categories-slider__item"
                    key={cat._id}
                  >
                    <Link
                      className="ic-categories-slider__item-link"
                      key={cat._id}
                      to={`/${cat.slug}`}
                    >
                      <img
                        alt=""
                        src={cat.menu_icon?.url}
                        className={`ic-categories-slider__image ${
                          Math.round(scroll) >= 80 ? 'active' : ''
                        }`}
                      />
                      <div
                        className={`ic-categories-slider__item-title ${
                          Math.round(scroll) >= 80 ? 'active' : ''
                        }`}
                        style={cat.color ? { color: cat.color } : {}}
                      >
                        {cat.title}
                        <div className="ic-categories-slider__item-button"></div>
                      </div>
                    </Link>
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        )}

      {width >= 640 && <CartButtonScroll scroll={scroll} />}
    </>
  );
};
export default CategoriesSlider;
