const ru = {
  translation: {
    About: 'О нас',
    'About Us': 'О нас',
    Account: 'Аккаунт',
    'Account updated successfull': 'Аккаунт успешно обновлен',
    Add: 'добавить',
    'Add to cart': 'Добавить в корзину',
    Address: 'Адрес',
    agreement: 'соглашение',
    Blog: 'Блог',
    Brands: 'Бренды',
    'Buy the look': '',
    Cart: 'Корзина',
    Cart__1: 'Корзина',
    Catalog: 'Каталог',
    Categories: 'Категории',
    Categories__1: '',
    'Change Password': 'Изменить пароль',
    Checkout: 'Оформление заказа',
    Clean: 'Очистить',
    'Contact Us': 'Связаться с нами',
    'Continue Shopping': 'Продолжить покупки',
    'Continue Srearching': 'Продолжить поиск',
    'Continue to checkout': 'Перейти к оформлению заказа',
    'Continue to payment': 'Перейти к оплате',
    'Copyright © All rights Reserved': '',
    'Coupon Applied': 'Скидка по купонам',
    'Coupon Code': 'Код купона',
    'Current password': 'Действующий пароль',
    'Date of Birth': 'Дата рождения',
    Description: 'Описание',
    Details: 'Подробности',
    Discount: 'Скидка',
    // Email: 'Э',
    Filter: 'Фильтр',
    'First Name': 'Имя',
    'Forgot password?': 'Забыли пароль?',
    Free: 'Бесплатно',
    Hi: 'Привет',
    Home: 'Главная страница',
    'I have read and agree to the': 'Я прочитал и принимаю',
    Information: 'Информация',
    'Last Name': 'Фамилия',
    'Log in': 'Вход',
    Login: 'Вход',
    'Login to continue': 'Войти чтобы продолжить',
    'Login with Facebook': 'Войти с аккаунтом Facebook',
    'Login with Google': 'Войти с аккаунтом Google',
    Logout: 'Выйти',
    'My Account': 'Мой аккаунт',
    'New card': 'Новая карта',
    'New password': 'Новый пароль',
    OR: 'или',
    Order: 'Заказ',
    'Order date': 'Дата заказа',
    'Order number': 'Номер заказа',
    'Order Summary': 'Сумма заказа',
    'Order was not paid': 'Заказ не был оплачен',
    Password: 'Пароль',
    'Pay Order': 'Оплатить заказ',
    'Payment in progress': 'Происходит процесс оплаты',
    'Payment success': 'Оплата прошла успешно',
    Phone: 'Tелефон',
    Price: 'Цена',
    'Privacy-policy': 'Политика сайта',
    'Qty (quantity)': 'Количество',
    Qty: 'Кол-во',
    Quantity: 'Количество',
    'register now': 'Регистрируйтесь сейчас',
    Remove: 'Удалить',
    'Restore password': 'Восстановление пароля',
    'Returns & Warranty': 'Возвраты и гарантии',
    Reviews: 'Отзывы',
    Search: 'Поиск',
    'Select shipment method': 'Выбор способа доставки',
    'Send validation code': 'Выслать код подтверждения',
    Shipment: 'Доставка',
    Shipping: 'Доставка',
    Shop: 'Магазин',
    'Should accept agreement': 'Пожалуйста подтвердите согласие с договором',
    Show: 'Показать',
    'Sign out': 'Выйти',
    'Size Chart': '',
    'Sort By': 'Сортировать по',
    Speciations: 'Спецификации',
    Status: 'Статус',
    'Subtotal Price': 'Предварительная стоимость',
    Total: 'Итого',
    Update: 'Обновить',
    'was payed successfull': 'Оплата успешно завершина',
    Wishlist: 'Список избранных товаров',
    'You will receive updated email soon':
      'Вы получите сообщение об обновлении в ближайшее время',
    'Your shopping cart is empty': 'Корзина покупок пуста',
    'Your Wishlist is empty': 'Список избранных товаров пуст',
    Next: 'Следующий',
    Prev: 'Предыдущий',
    'What people says about this product': 'Что люди говорят об этом продукте',
    'Customers recommended this product': 'Клиентов рекомендовали этот продукт',
    'Add new comment': 'Добавить новый коментарий',
    'out of': 'из',
    'Add Comment': 'Добавить коментарий',
    'Add Reply': 'Добавить ответ',
    'Our products': 'Наши продукты',
    'Basic information': 'Основная информация',
    'Contact information': 'Контактная информация',
    'Changing profile': 'Изменение профиля',
    'Сhanging contact information': 'Изменение контактной информации',
    'Сhanging password': 'Смена пароля',
    Orders: 'Заказы',
    'My orders': 'Мои заказы',
    Delivery: 'Доставка',
    'Delivery date': 'Дата доставки',
    'Total paid': 'Оплаченные',
    'Order preparation': 'Подготовка заказа',
    'Waiting for sending': 'Ожидание отправки',
    'Was send': 'Отправлен',
    'Client recieved': 'Доставлен',
    All: 'Все',
    'Payments methods': 'Способы оплаты',
    Delete: 'Удалить',
    'Are you shure': 'Вы уверены',
    Yes: 'Да',
    No: 'Нет',
    Returns: 'Возвраты',
    'My cart': 'Корзина',
    // Items: 'товаров',
    '{count, plural, =1{# item} other{# items}}':
      '{count, plural, =1{# товар} one{# товар} few{# товара} many{# товаров} other{# товара}}',
    // Qty: 'К-во',
    KG: 'кг',
    'per KG': 'за кг',
    'View cart': 'Посмотреть корзину',
    'Price per kg': 'Цена за кг',
    'per 100 gr': 'за 100 гр',
    SKU: 'Артикул',
    Popular: 'Популярности',
    Sale: 'Распродажа',
    Apply: 'Применить',
    'Terms & Conditions': 'Условия использования',
    'Privacy policy': 'Политика конфиденциальности',
    Payments: 'Способы оплаты',
    Payment: 'Оплата',
    'Address Information': 'Личная информация',
    'Shipment Information': 'Информация о доставке',
    'Select address': 'Выберите адрес',
    'Postal code': 'Индекс',
    City: 'Город',
    Street: 'Улица',
    Building: 'Номер дома',
    Flat: 'Квартира',
    'Save contact information': 'Сохранить контактную информацию',
    'Continue to shipping': 'Продолжить оформление',
    'Your cart': 'Моя корзина',
    'Edit cart': 'Редактировать корзину',
    Installments: 'Кол-во платежей',
    'Validate email': 'Проверка электронной почты',
    'We sent confirmation code to your email address.':
      'Мы отправили код подтверждения на ваш адрес электронной почты.',
    'Validation code': 'Код проверки',
    'Check validation code': 'Ввести код проверки',
    'Your comment added successful. It will be showen after moderation':
      'Ваш комментарий добавлен успешно. Он будет показан после модерации',
    'Product added to cart': 'Продукт добавлен в корзину',
    'Up to 1 day': 'До 1 дня',
    'Get {quantity} for {price}': 'Купить {quantity} за {price}',
    Sales: 'Распродажа',
    Recommendations: 'Рекомендуем',
    'Card Number': 'Номер кредитной карты',
    'Expiry Date': 'Срок действия',
    CVV: 'CVV',
    Close: 'Закрыть',
    Pay: 'Оплата',
    'Enter card details': 'Ввести данные карты',
    'Send verification SMS': 'Отправить SMS с подтверждением',
    'We sent confirmation code to your phone. Phone number':
      'Мы отправили код подтверждения на ваш телефон. Номер телефона',
    'Validate phone': 'Подтвердить телефон',
    'Total before discounts': 'Итого без скидок',
    'From {quantity} per {price}': 'От {quantity} за {price}',
    'Sale Now': 'Акция',
    Srt: 'Сорт:',
    'See all': 'Смотреть всё',
    'Operation! Order over ': 'Акция! При заказе на сумму более ',
    'NIS Free shipping!': ' шекелей — бесплатная доставка!',
    Edit: 'Редактировать',
    'Details order': 'Детали',
    'Order price': 'Стоимость заказа',
    'View order': 'Посмотреть заказ',
    'Please select the store closest to you':
      'Пожалуйста, выберите ближайший к вам магазин',
    Confirm: 'Подтвердить',
    'You have items in your shopping cart. When you change your address, your shopping cart will be emptied. Do you wish to continue?':
      'Пожалуйста, обратите внимание, что в корзине находятся  товары. В случае смены магазина оформляющего ваш заказ, корзина покупок будет очищена от всех ранее выбранных товаров.',
    'Edit profile': 'Редактировать профиль',
    'Edit avatar': 'Выбрать аватар',
    'View entire order': 'Смотреть весь заказ',
    'Repeat order': 'Повторить заказ',
    'Download check': 'Скачать чек',
    'Cost of goods': 'Стоимость товаров ',
    'Shipment price': 'Стоимость доставки',
    Amount: 'Сумма',
    'Client cancelation': 'Отмена клиентом',
    'System aborted': 'Ошибка системы',
    'Just created': 'Только что создан',
    'Product reservation': 'Бронирование продукта',
    "You don't have any orders yet": 'У Вас пока нет заказов',
    "You don't have any returns yet": 'У Вас пока нет возвратов',
    'Place your first order to add a payment method':
      'Сделайте первый заказ, чтобы добавить способ оплаты',

    'פתח תקווה': 'Петах-Тиква',
    'בת ים': 'Бат-Ям',
    אשדוד: 'Ашдод',
    אשקלון: 'Ашкелон',
    לוד: 'Лод',
    'Enter your name': 'Введите имя',
    'Enter your last name': 'Введите фамилию',
    'Enter your date of birth': 'Введите дату рождения',
    'Enter your Email address': 'Введите Email адрес',
    'Filter by status': 'Показать со статусом',
    'Add to cart {quantity} per {price}':
      'Добавить в корзину {quantity} за {price}',
    'To cart {quantity} per {price}': 'В корзину {quantity} за {price}',
    'pts.': 'шт.',
    'There are no products in this category': 'В этой категории нет товаров',

    //
    'Start typing address here': 'Начните писать адрес здесь',
    'Please, write down Bulding Number after Street name':
      'Пожалуйста, введите Номер дома после названия улицы',
    'Recepient Information': 'Информация о получателе',
    'Shipment Address': 'Адрес доставки',

    'Please, select city you want to deliver orders':
      'Для заказа товаров, пожалуйста введите в строке ниже, город доставки.',

    'Unfortunatly we have no possibility to deliver to this address':
      'К сожалению, в этом городе доставка пока не доступна. Попробуйте ввести другой город',

    'Phone number is invalid': 'Номер телефона не верный',
    'You have {amount} bonuses now':
      'На данный момент вам доступно {amount} бонусов',
    Date: 'Дата',
    Info: 'Информация',
    'Spent for order #{order}': 'Списано на оплату заказа #{order}',
    'Got for order #{order}': 'Начислено за оплату заказа #{order}',
    Bonuses: 'Бонусы',
    'Coupons & Bonuses Discount': 'Скидка по купонам и бонусам',
    'You have {credits} bonuses. You can use it for payment':
      'Вам доступно {credits} бонусов. Ими возможно воспользоваться для частичной оплаты заказа',
    'Apply bonuses': 'Применить бонусы',
    'You have {credits} bonuses.': 'Вам доступно {credits} бонусов.',
    'You can use it for payment':
      'Ими возможно воспользоваться для частичной оплаты заказа',
    Skip: 'Пропустить',
    'Pay with bonuses': 'Оплатить бонусами',
    'You can use for this order {maxtotal}':
      'Возможно использовать для оплаты заказа {maxtotal}',
    'Enter bonuses amount': 'Введите кол-во бонусов',
    'Please, select shipment method': 'Пожалуйста, выберите способ доставки',
    'We have another branch closest to this address. Please, change branch you want to order':
      'У нас есть отделение ближе к этому адресу. Пожалуйста, выберите другое отделение для заказа',

    'Order comments': 'Комментарии к заказу',

    'Cashless payment': 'Безналичная оплата',
    'Unfortunately we have no possibility to deliver to this address':
      'К сожалению, у нас нет возможности доставить по этому адресу.',
    'To deliver to this address you need to change a branch to {name}':
      'Для доставки заказа по вашему адресу, пожалуйста выбирите отделение {name}. Внимание! ассортимент в разных подразделения может различаться',
  },
};
export default ru;
