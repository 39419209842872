import {Button, Form, Input, Modal, notification} from 'antd';
import {useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import CommonStore from '../../store/CommonStore';
import ConfirmationPhone from '../ConfirmationPhone';
import PhoneInput from 'react-phone-input-2';
import {toJS} from 'mobx';
import api from '../../api/api';
import UserLoginForm from '../UserLoginForm';

//
const {Item} = Form;

const ContactUs = () => {
  //
  const {t} = useTranslation();
  //
  const [form] = Form.useForm();
  //
  const storeCommon = useContext(CommonStore);
  //
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);

  const sendToEmail = async (value: any) => {
    setLoading(true);
    const newValue = {
      message: value.message,
      email: storeCommon.user?.email,
      // shop_email: storeCommon.shop?.email,
      shop_email: 'skippik1111@gmail.com',
    };
    const resp = await api('api/contact-us', 'POST', newValue);
    setLoading(false);
    if (!resp) {
      notification['error']({
        message: t('Error'),
        description: t('Message not sent'),
      });
      return;
    } else {
      setOpen(false);
      notification['success']({
        message: t('Success'),
        description: t('Message sent'),
      });
    }
  };

  return (
    <>
      <Button
        onClick={() => {
          if (!!storeCommon.user) {
            setOpen(true);
          } else {
            setVisible(true);
          }
        }}
        style={{padding: 0}}
        type='text'
        className='ic-footer__about-item'>
        {t('Contact Us')}
      </Button>
      <UserLoginForm
        visible={visible}
        setVisible={visible => {
          setVisible(visible);
        }}
        loginCallback={() => {
          setVisible(false);
          setOpen(true);
        }}
      />
      <Modal
        open={open}
        onCancel={() => {
          setOpen(false);
        }}
        onOk={() => {
          setOpen(false);
        }}
        title={t('Contact Us')}
        footer={false}>
        <Form
          labelCol={{span: 24}}
          wrapperCol={{span: 24}}
          name='basic'
          id='shipment-form'
          onFinish={sendToEmail}
          onFinishFailed={({
            values,
            errorFields,
          }: {
            values: any;
            errorFields: {errors: string[]}[];
          }) => {
            if (errorFields.length) {
              notification.error({
                message: errorFields
                  .map(error => error.errors.join('. '))
                  .join('\n'),
              });
            }
          }}
          form={form}
          // key={!!gettDelivery.status ? gettDelivery.status : 0}
          scrollToFirstError>
          <Item name={'message'} label={t('Message')}>
            <Input.TextArea rows={5} placeholder={t('Message')} />
          </Item>
          <Button
            loading={loading}
            type='primary'
            htmlType='submit'
            block
            style={{marginTop: 20}}>
            {t('Send')}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default ContactUs;
