import { action, observable, makeObservable, toJS } from "mobx";
import { createContext } from "react";
import api from "../api/api";
import {
  Branch,
  CartProduct,
  Category,
  Product,
  Promotion,
  PromotionType,
  Shop,
  User,
} from "../types";
import { base64, round } from "../utils/formatter";
import {
  getCurrentProductVariation,
  recalculateCartWithPromotions,
} from "../utils/product";

class CommonStore {
  user: User | null = null;
  shop: Shop | null = null;
  branch: Branch | null = null;

  cart: CartProduct[] = [];
  wishlist: string[] = [];

  constructor() {
    makeObservable(this, {
      user: observable,
      shop: observable,
      branch: observable,
      cart: observable,
      wishlist: observable,

      setUser: action,
      setShop: action,
      addToCart: action,
      removeFromCart: action,
      clearCart: action,

      toggleWishlist: action,
      clearWishlist: action,

      getProductRoute: action,
    });

    if (localStorage.getItem("cart")) {
      this.cart = JSON.parse(base64.decode(localStorage.getItem("cart") || ""));
    }

    if (localStorage.getItem("wishlist")) {
      this.wishlist = JSON.parse(
        base64.decode(localStorage.getItem("wishlist") || "")
      );
    }

    this.updateProductsFromServer();
  }

  // USER functions
  setUser = (user: User | null) => {
    this.user = user;
  };

  setShop = (shop: Shop) => {
    this.shop = shop;
  };

  setBranch = (branch: Branch) => {
    this.branch = branch;
  };

  // CART functions
  addToCart = (object: CartProduct, maxCount = 0) => {
    // console.log('CartProduct', object.quantity);

    const index = this.cart.findIndex(
      (cart) =>
        cart.product_id === object.product_id &&
        JSON.stringify(cart.variant) === JSON.stringify(object.variant) &&
        cart.promotion_id === object.promotion_id
    );
    if (index < 0) {
      this.cart.unshift(object);
    } else {
      if (maxCount > 0) {
        if (this.cart[index].quantity + object.quantity > maxCount) {
          this.cart[index].quantity = maxCount;
        } else {
          this.cart[index].quantity = round(
            this.cart[index].quantity + object.quantity,
            2
          );
        }
      } else {
        this.cart[index].quantity = round(
          this.cart[index].quantity + object.quantity,
          2
        );
      }
    }

    this.cart = recalculateCartWithPromotions(this.cart);

    localStorage.setItem(
      "cart",
      base64.encode(
        JSON.stringify(
          this.cart.map((cart) => {
            const { product, total, total_discounted, ...rest } = cart;
            return { ...rest };
          })
        )
      )
    );
  };

  changeCartQuantity = (
    object: CartProduct,
    quantity: number,
    maxCount = 0
  ) => {
    if (quantity <= 0) {
      this.removeFromCart(object);
      return;
    }
    const index = this.cart.findIndex(
      (cart) =>
        cart.product_id === object.product_id &&
        JSON.stringify(cart.variant) === JSON.stringify(object.variant) &&
        cart.promotion_id === object.promotion_id
    );
    if (index < 0) {
      return;
    }
    this.cart[index].quantity = maxCount
      ? Math.min(maxCount, quantity)
      : quantity;

    this.cart = recalculateCartWithPromotions(this.cart);

    localStorage.setItem(
      "cart",
      base64.encode(
        JSON.stringify(
          this.cart.map((cart) => {
            const { product, total, total_discounted, ...rest } = cart;
            return { ...rest };
          })
        )
      )
    );
  };

  removeFromCart = (object: CartProduct) => {
    const index = this.cart.findIndex(
      (cart) =>
        cart.product_id === object.product_id &&
        JSON.stringify(cart.variant) === JSON.stringify(object.variant) &&
        cart.promotion_id === object.promotion_id
    );
    if (index >= 0) {
      this.cart.splice(index, 1);
    }

    localStorage.setItem(
      "cart",
      base64.encode(
        JSON.stringify(
          this.cart.map((cart) => {
            const { product, total, total_discounted, ...rest } = cart;
            return { ...rest };
          })
        )
      )
    );
  };

  clearCart = () => {
    this.cart = [];
    localStorage.removeItem("cart");
  };

  // CART functions
  toggleWishlist = (_id: string) => {
    const index = this.wishlist.findIndex((product_id) => product_id === _id);
    if (index < 0) {
      this.wishlist.push(_id);
    } else {
      this.wishlist.splice(index, 1);
    }

    localStorage.setItem(
      "wishlist",
      base64.encode(JSON.stringify(this.wishlist))
    );
  };

  clearWishlist = () => {
    this.wishlist = [];
    localStorage.removeItem("wishlist");
  };

  // ROUTE
  getProductRoute = (product: Product) => {
    if (!this.shop) {
      return "";
    }

    let route: Category[] | undefined;
    let counter = 0;

    while (!route && product.categories.length > counter) {
      route = searchTree(
        {
          _id: "",
          children: this.shop.categories,
          title: "",
          description: "",
          slug: "",
          parent_id: null,
          promotion: false,
          promotion_image: null,
          header_image: null,
          menu_icon: null,
        },
        product.categories[counter]
      );
      counter++;
    }

    return ["", ...(route || []).map((c) => c.slug), product.slug].join("/");
  };

  /**
   *
   */
  updateProductsFromServer = async () => {
    if (this.cart.length === 0) {
      return;
    }

    const response: { data: Product[]; error?: string } = await api(
      `products?per-page=100&${this.cart
        .map((p) => `filter[_id][in][]=${p.product_id}`)
        .join("&")}`
    );

    // console.log('updateProductsFromServer', response.data);

    const newCart: CartProduct[] = [];

    this.cart.forEach((cart) => {
      const mainProduct = response.data.find((p) => p._id === cart.product_id);
      if (!mainProduct) {
        return;
      }
      const product = getCurrentProductVariation(mainProduct, cart.variant);
      if (!product) {
        return;
      }

      newCart.push({
        ...cart,
        product,
      });
    });

    this.cart = recalculateCartWithPromotions(newCart);

    localStorage.setItem(
      "cart",
      base64.encode(
        JSON.stringify(
          this.cart.map((cart) => {
            const { product, total, total_discounted, ...rest } = cart;
            return { ...rest };
          })
        )
      )
    );
  };
}

export default createContext(new CommonStore());

const searchTree = (parent: Category, _id: string) => {
  const stack: [Category, Category[]][] = [[parent, []]];
  while (stack.length) {
    const [node, path] = stack.pop() || [];
    if (node && path) {
      if (node._id === _id) {
        return path;
      }
      if (node.children) {
        stack.push(
          ...node.children.map(
            (node) => [node, [...path, node]] as [Category, Category[]]
          )
        );
      }
    }
  }
};
