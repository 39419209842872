import { toJS } from 'mobx';
import { CartProduct, Product, Promotion } from '../types';
import { round } from './formatter';

export const getCurrentProductVariation: (
  product: Product,
  variant: { option: string; value: string }[] | null
) => Product | null = (product, variant) => {
  let ret = {
    ...product,
  };

  if (variant) {
    const variation = product.variants.find(
      (v) => JSON.stringify(v.values) === JSON.stringify(variant)
    );
    if (!variation) {
      return null;
    }

    const images = product.variants_images.find(
      (o) => variant.map((v) => v.value).indexOf(o.value) >= 0
    )?.images;

    ret = {
      ...product,
      sku: variation.sku,
      price: variation.price ? variation.price : product.price,
      sale_price: variation.sale_price
        ? variation.sale_price
        : product.sale_price,
      stock: variation.stock,
      sale_from: variation.sale_from ? variation.sale_from : product.sale_from,
      images: images || product.images,
    };
  }

  if ((ret.sale_from || 0) * 1000 < new Date().valueOf() && ret.sale_price) {
    ret.real_price = ret.sale_price;
  } else {
    ret.real_price = ret.price;
  }

  return ret as Product;
};

export const recalculateCartWithPromotions = (cart: CartProduct[]) => {
  const newCart: CartProduct[] = [];

  const promotions: Promotion[] = [];

  cart.forEach((c) => {
    if (c.product.promotion) {
      if (
        !promotions.map((promo) => promo._id).includes(c.product.promotion._id)
      ) {
        promotions.push(c.product.promotion);
      }
    }
  });

  // console.log("PROMOTIONS  ==>>", toJS(promotions));

  const promoObject: {
    [key: string]: {
      promotion: Promotion;
      products: CartProduct[];
    };
  } = {};

  cart.forEach((cp) => {
    promotions.forEach((promo) => {
      if (promo.products.includes(cp.product_id)) {
        if (!promoObject[promo._id]) {
          promoObject[promo._id] = {
            promotion: promo,
            products: [],
          };
        } else {
          if (
            promoObject[promo._id].products
              .map((p) => p.product_id)
              .includes(cp.product_id)
          ) {
            return;
          }
        }

        promoObject[promo._id].products.push(cp);
      }
    });
  });
  // console.log("promoObject  ==>>", promoObject);

  Object.values(promoObject).forEach((obj) => {
    if (
      obj.products.reduce((a, b) => a + b.quantity, 0) <
      Math.max(obj.promotion.quantity, obj.promotion.min)
    ) {
      obj.products.forEach((c) => {
        newCart.push({
          ...c,
          total_discounted: undefined,
          total: round(c.quantity * c.product.real_price, 2),
        });
      });
      return;
    }
    const totalProducts = obj.products.reduce((a, b) => a + b.quantity, 0);
    let productDiscounted =
      obj.promotion.min > 0
        ? totalProducts
        : totalProducts - (totalProducts % obj.promotion.quantity);
    // console.log("productDiscounted  ==>>", productDiscounted);

    obj.products
      .sort((a, b) => b.quantity - a.quantity)
      .forEach((p) => {
        const real_price = p.product.real_price;
        const discount_price =
          obj.promotion.min > 0
            ? obj.promotion.price || real_price
            : round((obj.promotion.price || 0) / obj.promotion.quantity, 4);
        let total_discounted = 0;

        if (obj.promotion.min > 0) {
          total_discounted = round(p.quantity * discount_price, 4);
        } else {
          for (let i = 0; i < p.quantity; i++) {
            total_discounted = round(
              total_discounted +
                (productDiscounted > 0 ? discount_price : real_price),
              4
            );
            productDiscounted--;
          }
        }

        newCart.push({
          ...p,
          total_discounted:
            round(total_discounted, 2) !== round(p.quantity * real_price, 2)
              ? round(total_discounted, 2)
              : undefined,
          total: round(p.quantity * real_price, 2),
        });
      });
  });

  // console.log("newCart", newCart);

  return cart.map((c) => {
    const finded = newCart.find(
      (nc) =>
        c.product_id === nc.product_id &&
        JSON.stringify(c.variant) === JSON.stringify(nc.variant) &&
        c.promotion_id === nc.promotion_id
    );
    return (
      finded || {
        ...c,
        total: round(
          c.quantity *
            (c.product.real_price !== c.product.price
              ? c.product.price
              : c.product.real_price),
          2
        ),
        total_discounted:
          c.product.real_price !== c.product.price
            ? round(c.quantity * c.product.real_price, 2)
            : undefined,
      }
    );
  });
};
