import {Form, Input, Button, Checkbox} from 'antd';
import {Dispatch, SetStateAction, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';
import api from '../../api/api';
import CommonStore from '../../store/CommonStore';
import {Link} from 'react-router-dom';
import {LoginAnswer, LoginStepType} from '../../types';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/high-res.css';
import {isValidPhoneNumber, parsePhoneNumber} from 'libphonenumber-js';

interface LoginFormProps {
  // loginCallback: () => void;
  setStep: (step: LoginStepType) => void;
  onClose: () => void;
  setPhone: Dispatch<SetStateAction<string>>;
  phone: string;
}

const Login = ({
  // loginCallback,
  setStep,
  onClose,
  setPhone,
  phone,
}: LoginFormProps) => {
  //
  const {t, i18n} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const [loading, setLoading] = useState(false);

  //
  const [form] = Form.useForm();

  //
  const checkPhone = (_: any, value: string) => {
    // console.log(
    //   value,
    //   'isValidPhoneNumber(value.phone, IL)',
    //   isValidPhoneNumber(value, 'IL')
    // );

    if (value && isValidPhoneNumber(value, 'IL')) {
      return Promise.resolve();
    }
    return Promise.reject(new Error(t('Phone number is invalid')));
  };

  //
  const onFormSubmit = async (values: {phone: string}) => {
    setLoading(true);

    const response: LoginAnswer = await api('api/send-sms', 'POST', {
      phone: parsePhoneNumber(
        values.phone.startsWith('+972') ? values.phone : `+972${values.phone}`,
        'IL',
      ).formatInternational(),
    });

    setLoading(false);
    if (response?.error) {
      response.error.forEach(item => {
        form.setFields([
          {
            name: item.field,
            errors: [item.message],
          },
        ]);
      });

      return;
    }

    // if (response.user) storeCommon.setUser(response.user);
    // if (response.token) localStorage.setItem("token", response.token);

    // if (response.status !== 10) {
    setPhone(
      parsePhoneNumber(
        values.phone.startsWith('+972') ? values.phone : `+972${values.phone}`,
        'IL',
      ).formatInternational(),
    );
    setStep('validation');
    //   return;
    // }

    // form.resetFields();
    // loginCallback();
  };

  return (
    <Form
      className='ic-login'
      name='login-form'
      initialValues={{accept: true, phone}}
      onFinish={onFormSubmit}
      form={form}>
      {/* <Form.Item
        className="ic-login__email"
        name="email"
        label={t("Email")}
        rules={[{ required: true }, { type: "email" }]}
        labelCol={{ hidden: true }}
      >
        <Input type="email" placeholder={t("Email")} />
      </Form.Item> */}
      {/* <Form.Item
        className="ic-login__password"
        name="password"
        label={t("Password")}
        rules={[{ required: true }]}
        labelCol={{ hidden: true }}
      >
        <Input.Password visibilityToggle placeholder={t("Password")} />
      </Form.Item> */}

      <Form.Item
        name='phone'
        label={t('Enter your mobile phone number')}
        // rules={[{required: true}]}
        labelCol={{hidden: true}}
        className='ic-login__phone'
        rules={[/*{ required: true },*/ {validator: checkPhone}]}
        getValueFromEvent={(value, data, event, formattedData) =>
          formattedData
        }>
        <PhoneInput
          // defaultCountry="il"
          country={'il'}
          onlyCountries={['il']}
          preferredCountries={['il']}
          disableDropdown
          disableCountryCode
          showDropdown={false}
          // value={
          //   order?.user.phone
          //     ? order.user.phone
          //     : storeCommon.user?.phone || ''
          // }
          buttonClass='btn-phone'
          inputProps={{
            className: 'ant-input ant-input-lg ic-login__phone-input ',
            style: {
              paddingLeft: 70,
              paddingRight: 70,
              direction: 'ltr',
              textAlign: i18n.language === 'he' ? 'right' : 'left',
            },
            // onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
            //   console.log(
            //     'onkeyup',
            //     (e.target as HTMLInputElement).value
            //   );
            //   const phone = (e.target as HTMLInputElement).value;
            //   if (phone) {
            //     // console.log('phone ==>> ', values.user.phone);
            //     if (phone.replaceAll(' ', '').startsWith('+9720')) {
            //       // console.log(
            //       //   'phone ==>> ',
            //       //   phone.replaceAll(' ', '').replace('+9720', '+972')
            //       // );
            //       // form.setFieldValue(
            //       //   ['user', 'phone'],
            //       //   phone.replaceAll(' ', '').replace('+9720', '+972')
            //       // );
            //       (e.target as HTMLInputElement).value = phone
            //         .replaceAll(' ', '')
            //         .replace('+9720', '+972');

            //       // if (phoneRef.current) {
            //       //   phoneRef.current;
            //       // }
            //     }
            //   }
            // },
          }}
          placeholder='054 987 65 43'
          // masks={{ il: '.. ... .. ..' }}
          enableAreaCodeStretch
          // areaCodes={{ il: ['5'] }}
        />
      </Form.Item>

      {/* <Button
        type="link"
        className="ic-login__forgot"
        onClick={() => setStep("forgot")}
      >
        {t("Forgot password?")}
      </Button> */}

      <Form.Item
        className='ic-login__agreement'
        name='accept'
        valuePropName='checked'
        rules={[
          {
            validator: (_, value) =>
              value
                ? Promise.resolve()
                : Promise.reject(new Error(t('Should accept agreement'))),
          },
        ]}>
        <Checkbox className='ic-login__checkbox'>
          {t('I have read and agree to the')}{' '}
          <Link to='/terms' onClick={onClose}>
            {t('Terms & Conditions')}
          </Link>
        </Checkbox>
      </Form.Item>

      <Button
        className='ic-login__button-login'
        type='primary'
        htmlType='submit'
        block
        loading={loading}>
        {t('Send verification SMS')}
      </Button>
      {/* <Button
        className="ic-login__button-register"
        type="text"
        onClick={() => setStep("signup")}
      >
        {t("register now")}
      </Button> */}
    </Form>
  );
};

export default Login;
