import { useEffect } from 'react';

interface OtpInputProps {
  length: number;
  onChange?: (value: string) => void;
}

const OtpInput = ({ length, onChange }: OtpInputProps) => {
  //
  const handleInput = (e: any) => {
    let inputField = e.target;
    if (inputField.value.length >= 1) {
      let nextField = inputField.nextElementSibling;
      if (nextField) {
        nextField.focus();
      } else {
        const inputFields = document.querySelectorAll('input.field');
        let value = '';
        inputFields.forEach((input: any) => {
          value += input.value.toString();
        });
        if (onChange) {
          onChange(value);
        }
      }
    }
  };

  //
  const handleBackspace = (e: Event) => {
    if ((e as KeyboardEvent).key !== 'Backspace') {
      return;
    }
    if (!(e.target as HTMLInputElement)?.previousElementSibling) {
      return;
    }
    const currentInput = e.target as HTMLInputElement;
    if (currentInput.value) {
      return;
    }
    const prevInput = (e.target as HTMLInputElement)
      .previousElementSibling as HTMLInputElement;
    prevInput.value = '';
    prevInput.focus();
    return;
  };

  //
  useEffect(() => {
    const inputFields = document.querySelectorAll('input.field');

    inputFields.forEach((field) => {
      field.addEventListener('input', handleInput);
      field.addEventListener('keydown', handleBackspace);
    });

    (inputFields[0] as HTMLInputElement).focus();

    return () => {
      inputFields.forEach((field) => {
        field.removeEventListener('input', handleInput);
        field.removeEventListener('keydown', handleBackspace);
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        // gridTemplateColumns: `repeat(${length}, 1fr)`,
        justifyContent: 'space-between',
      }}
      dir="ltr"
    >
      {new Array(length).fill(0).map((_, index) => (
        <input
          key={`code_input_key_${index}`}
          type="number"
          className={`field ${index}`}
          maxLength={1}
          style={{
            width: 50,
            lineHeight: '75px',
            maxHeight: 80,
            fontSize: 32,
            border: 'none',
            backgroundColor: '#EAF5F6',
            borderRadius: 5,
            textAlign: 'center',
            color: '#093030',
            // marginBottom: 25,
          }}
        />
      ))}
    </div>
  );

  // return (
  //   <input
  //     type="number"
  //     style={{
  //       width: "100%",
  //       height: 75,
  //       lineHeight: 75,
  //       fontSize: 32,
  //       direction: "ltr",
  //       letterSpacing: 50,
  //     }}
  //   />
  // );
};

export default OtpInput;
