const he = {
  translation: {
    // Login: 'התחברות',
    "It's a banner title": 'כותרת של הבאנר',
    'Here is a text for this banner<br/>Those title and text could be replaced during creation or editing of the store':
      'טקסט, כותרת והתמונת רקע של הבארר אפשר לשנות<br/>דרך מערכת ניהול של החנות (סעיף ״תוכן״)',
    'Our Premium Collection': 'קולקציית הפרימיום שלנו',
    'Our products': 'מוצרים שלנו',
    'Our Menu': 'תפריט',
    'Our Collection': 'קולקציות',
    'Popular Categories': 'קטגוריות פופולריות',

    About: 'אודות',
    'About Us': 'אודותינו',
    Account: 'חשבון משתמש',
    'Account updated successfull': 'חשבון משתמש העודכן בהצלחה',
    Add: 'להוסיף',
    'Add to cart': 'להוסיף לסל',
    Address: 'כתובת',
    agreement: 'הסכם',
    Blog: 'בלוג',
    Brands: 'מותגים',
    'Buy the look': 'קנה את המראה',
    Cart: 'סל',
    Cart__1: 'סל',
    Catalog: 'קטלוג',
    Categories: 'קטגוריות',
    'Change Password': 'שינוי סיסמה',
    Checkout: 'מעבר לקופה',
    Clean: 'נקה',
    'Contact Us': 'צור קשר',
    'Continue Shopping': 'המשך קניות בחנות',
    'Continue Srearching': 'המשך חיפוש',
    'Continue to checkout': 'המשך לקופה',
    'Continue to payment': 'המשך לתשלום',
    // 'Copyright © All rights Reserved': '',
    'Coupon Applied': 'קופון מומש',
    'Coupon Code': 'קוד קופון',
    'Current password': 'סיסמה הנוחכית',
    'Date of Birth': 'תאריך לידה',
    Description: 'תיאור',
    Details: 'פרטים',
    Discount: 'הנחה',
    Email: 'דואר אלקטרוני',
    Filter: 'סנן',
    'First Name': 'שם פרטי',
    'Forgot password?': 'שחכתי סיסמה',
    Free: 'ללא תשלום',
    Hi: 'היי',
    Home: 'עמוד הבית',
    'I have read and agree to the': 'קראתי ואני מסכים',
    Information: 'מידע',
    'Last Name': 'שם משפחה',
    'Log in': 'כניסה',
    Login: 'כניסה',
    'Login to continue': 'להיכנס כדי להמשיך',
    'Login with Facebook': 'להיכנס עם פייסבוק',
    'Login with Google': 'להיכנס עם גוגל',
    Logout: 'ניתוק',
    'My Account': 'החשבון שלי',
    'New card': 'כרטיס חדש',
    'New password': 'סיסמה חדשה',
    OR: 'או',
    Order: 'הזמנה',
    'Order date': 'תאריך ההזמנה',
    'Order number': 'מספר ההזמנה',
    'Order Summary': 'סכום ההזמנה',
    'Order was not paid': 'תשלום הזמנה טרם הושלם',
    Password: 'סיסמה',
    'Pay Order': 'לשלם ההזמנה',
    'Payment in progress': 'תשלום בתהליך',
    'Payment success': 'תשלום הושלם בהצלחה',
    Payments: 'תשלומים',
    Phone: 'מס׳ טלפון',
    'Power by': 'כוח על ידי',
    Price: 'מחיר',
    'Privacy-policy': 'תקנון האתר',
    'Privacy policy': 'תקנון האתר',
    'Qty (quantity)': 'כמות',
    Quantity: 'כמות',
    'register now': 'הרשם עכשיו',
    Remove: 'להסיר',
    'Restore password': 'שחזור סיסמה',
    'Returns & Warranty': 'אחריות והחזרות',
    Reviews: 'ביקורות',
    Search: 'חיפוש',
    'Select shipment method': 'בחירת שיטת משלוח',
    'Send validation code': 'שלח קוד אימות',
    Shipment: 'משלוח',
    Shipping: 'משלוח',
    Shop: 'חנות',
    'Should accept agreement': 'נא לאשר הסכם',
    Show: 'להציג',
    'Sign out': 'ניתוק',
    'Size Chart': 'גודל תרשים',
    'Sort By': 'למיין לפי',
    Speciations: 'סוגים',
    Status: 'סטטוס',
    'Subtotal Price': 'מחיר ביניים',
    Total: 'סה״כ',
    'Terms & Conditions': 'תנאי שימוש',
    Update: 'עדכון',
    'was payed successfull': 'תשלום עבר בהצלחה',
    Wishlist: 'רשימת מועדפים',
    'You will receive updated email soon': 'בקרוב תקבל עדכון במייל',
    'Your shopping cart is empty': 'סל קניות ריק',
    'Your Wishlist is empty': 'רשימת מועדפים ריקה',

    Next: 'הבא',
    Prev: 'הקודם',
    'What people says about this product': 'מה אנשים אומרים על מוצר זה',
    'Customers recommended this product': 'לקוחות מומלץ זה מוצר',
    'Add new comment': 'הוסף תגובה חדשה',
    'out of': 'מתוך',
    'Add Comment': 'הוסף תגובה',
    'Add Reply': 'הוסף תגובה',
    'Basic information': 'מידע בסיסי',
    'Contact information': 'מידע ליצירת קשר',
    Avatar: 'אווטאר',
    Orders: 'הזמנות',
    'Payments methods': 'שיטות תשלומים',
    Returns: 'החזרות',
    'Changing profile': 'שינוי פרופיל',
    'Сhanging contact information': 'שינוי פרטי קשר',
    'Сhanging password': 'שינוי סיסמה',
    'My orders': 'ההזמנות שלי',
    All: 'הכל',
    'Total paid': 'סך הכל שילם',
    'Order preparation': 'הכנת הזמנה',
    'Waiting for sending': 'מחכה לשליחה',
    'Was send': 'היה לשלוח',
    'Client recieved': 'הלקוח קיבל',
    View: 'הצג',
    Delivery: 'משלוח',
    'Delivery date': 'תאריך אספקה',
    'You currently have no saved payment methods.':
      'תאריך מסירה אין לך כרגע אמצעי תשלום שמורים.',
    'You can add or delete a card at any time.':
      'ניתן להוסיף או למחוק כרטיס בכל עת.',
    'Add card': 'הוסף כרטיס',

    Items: 'פריטים',
    'My cart': 'העגלה שלי',
    Qty: 'כמות',
    'View cart': 'הצג עגלה',
    'Address Information': 'פרטי כתובת',
    'Shipment Information': 'משלוח מידע',
    Payment: 'תשלום',
    'Edit cart': 'עריכת סל',
    Edit: 'עריכת',
    'Your cart': 'העגלה שלך',
    'Postal code': 'מיקוד',
    Street: 'רחוב',
    City: 'עיר',
    Building: 'בניין',
    'Save contact information': 'שמור פרטי קשר',
    'Continue to shipping': 'המשך למשלוח',
    Popular: 'פופולרי',
    Sale: 'מכירה',
    kg: 'ק״ג',
    KG: 'ק״ג',
    gr: 'גרם',
    'per KG': 'לק״ג',
    'per 100 gr': 'ל100 גרם',
    'Price per kg': 'מחיר ל ק״ג',
    '{count, plural, =1{# item} other{# items}}':
      '{count, plural, =1{# מוצר} other{# מוצרים}}',
    'Select address': 'בחר כתובת',
    Flat: 'דירה',

    'Validate email': 'אימות דוא״ל',
    'We sent confirmation code to your email address.':
      'קוד אימות נשלח לכתובת דוא״ל שצינת',
    'Validation code': 'קוד אימות',
    'Check validation code': 'בדיקת קוד אימות',
    'Your comment added successful. It will be showen after moderation':
      'הורעה שלך נשלחה בהצלחה, זה יופיע באתר אחרי אישור מנהל המערכת',
    'Product added to cart': 'המוצר נוסף לסל קניות',
    'Up to 1 day': 'פחות מיום אחד',
    'Get {quantity} for {price}': 'קנה {quantity} ב-{price}',
    Sales: 'מוצרים במבצע',
    Recommendations: 'מוצרים מומלצים',
    'Card Number': 'מספר כרטיס',
    'Expiry Date': 'תוקף הכרטיס',
    CVV: 'ספרות בגב הכרטיס',
    Close: 'סגור',
    Pay: 'לשלם',
    'Enter card details': 'הזן פרטי כרטיס',
    'Send verification SMS': 'שלח SMS אימות',
    'We sent confirmation code to your phone. Phone number':
      'שלחנו קוד אישור לטלפון שלך. מספר טלפון',
    'Validate phone': 'לאמת את מספר הטלפון',
    'From {quantity} per {price}': 'קנה מ-{quantity} רק ב-{price}',
    'Sale Now': 'עכשיו במבצע',
    'Total before discounts': 'סה״כ לפני הנחה',
    Srt: 'למיין לפי',
    'See all': 'ראה הכל',
    'Operation! Order over ': 'מבצע! בהזמנה מעל ',
    ' NIS Free shipping!': 'ש״ח משלוח חינם!',
    'View order': 'הצגת הזמנה',
    'Order price': 'מחיר הזמנה',
    'Details order': 'פרטים',
    'Please select the store closest to you': 'נא לבחור חנות הקרובה למיקומך',
    Confirm: 'אישור',
    'You have items in your shopping cart. When you change your address, your shopping cart will be emptied. Do you wish to continue?':
      'נא לשים לב! ישנם מוצרים בעגלת קניות. במידה ותשנה את החנות המטפל בהזמנתך, המערכת תרקן את עגלת הקניות שלך.',
    'Edit profile': 'ערוך פרופיל',
    'Edit avatar': 'ערוך אווטר',
    'View entire order': 'הצג את כל ההזמנה',
    'Download check': 'בדיקת הורדה',
    'Repeat order': 'חזור על ההזמנה',
    'Cost of goods': 'עלות סחורה',
    'Shipment price': 'מחיר משלוח',
    Amount: 'כמות',
    'Client cancelation': 'הלקוח ביטל הזמנה',
    'System aborted': 'המערכת בוטלה',
    'Just created': 'רק יצר הזמנה',
    'Product reservation': 'הזמנת מוצר',
    "You don't have any orders yet": 'עדיין אין לך הזמנות',
    "You don't have any returns yet": 'עדיין אין לך החזרות',
    'Place your first order to add a payment method':
      'בצע את ההזמנה הראשונה שלך כדי להוסיף אמצעי תשלום',
    Delete: 'למחוק',
    'Enter your name': 'הזן את שמך',
    'Enter your last name': 'הזן את שם המשפחה שלך',
    'Enter your date of birth': 'הכנס תאריך לידה',
    'Enter your Email address': 'הזן את כתובת האימייל שלך',
    'Filter by status': 'סנן לפי סטטוס',

    'Add to cart {quantity} per {price}': 'להוסיף לסל {quantity} ב- {price}',
    'To cart {quantity} per {price}': 'להוסיף לסל {quantity} ב- {price}',
    'pts.': 'יח.',
    'There are no products in this category': 'אין מוצרים בקטגוריה הזו',

    //
    'Start typing address here': 'נא להזין כתובת בשדה הזה',
    'Please, write down Bulding Number after Street name':
      'נא לציין מספר בית לאחר שם הרחוב',
    'Recepient Information': 'מידע על מקבל',
    'Shipment Address': 'כתובת למשלוח',

    'Please, select city you want to deliver orders':
      'להזמנת מוצרים נא להזין בשדה למטה את העיר למשלוח.',

    'Unfortunatly we have no possibility to deliver to this address':
      'מתנצלים...      נכון לעכשיו אנחנו לא מבצעים משלוחים לאזורכם.',

    'Phone number is invalid': 'מספר טלפון לא תקין',
    'You have {amount} bonuses now': 'נכון לעכשיו צברת {amount} בונוסים',
    Date: 'תאריך',
    Info: 'מידע',
    'Spent for order #{order}': 'חויבו עבור תשלום הזמנה  #{order}',
    'Got for order #{order}': 'קיבלת עבור תשלום הזמנה  #{order}',
    Bonuses: 'בונוסים',
    'Coupons & Bonuses Discount': 'הנחה לקופונים ובונוסים',
    'You have {credits} bonuses. You can use it for payment':
      'יש לך {credits}  בונוסים. ניתן לשתמש בהם לתשלום חלקי על ההזמנה',
    'Apply bonuses': 'להשתמש בבונוסים',
    'You have {credits} bonuses.': 'יש לך {credits} בונוסים.',
    'You can use it for payment': 'ניתן לשתמש בהם לתשלום חלקי על ההזמנה',
    Skip: 'דלג',
    Installments: 'מספר תשלומים',
    'Payed with bonuses': 'לשלם בבונוסים',
    'You can use for this order {maxtotal}':
      'אפשר להשתמש עבור ההזמנה הנוכחית {maxtotal}',
    'Enter bonuses amount': 'הזן מספר בונוסים',
    Apply: 'להשתמש',
    'Related products': 'מוצרים שקונים יחד',
    'Please, select shipment method': 'נא לבחור סוג משלוח',
    'Order comments': 'הערות להזמנה',
    'Cashless payment': 'תשלום טלפוני',
    'Are you sure?': 'לבצע תשלום טלפוני?',
    'To deliver to this address you need to change a branch to {name}':
      'על מנת להוציא משלוח לכתובת שלך נא  לבחור סניף {name} בחלק העליון של הדף',
    'Unfortunately we have no possibility to deliver to this address':
      'לצערינו אין אפשרות להוציא משלוח לעיר מיגורך',
  },
};
export default he;
