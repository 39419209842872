import { Result } from 'antd';
import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
// import AccountPage from "../pages/AccountPage";
import { Category, Shop } from '../types';

const HomePage = lazy(() => import('../pages/HomePage'));
const CategoryPage = lazy(() => import('../pages/CategoryPage'));
const ProductPage = lazy(() => import('../pages/ProductPage'));
const CartPage = lazy(() => import('../pages/CartPage'));
const CheckoutPage = lazy(() => import('../pages/CheckoutPage'));
const WishlistPage = lazy(() => import('../pages/WishlistPage'));
const AccountPage = lazy(() => import('../pages/AccountPage'));
const ContentPage = lazy(() => import('../pages/ContentPage'));

const AppRouter = ({ shop }: { shop: Shop }) => {
  const routes: string[] = [];

  const addToRotes = (route: string, cat: Category) => {
    routes.push(route + '/' + cat.slug);
    cat.children.forEach((sub) => {
      addToRotes(route + '/' + cat.slug, sub);
    });
  };

  shop.categories.forEach((cat) => {
    addToRotes('', cat);
  });

  return (
    <Suspense fallback={null}>
      <Switch>
        <Route path="/" exact render={() => <HomePage />} />
        <Route path="/he" exact render={() => <HomePage />} />
        <Route path="/ru" exact render={() => <HomePage />} />
        <Route path="/en" exact render={() => <HomePage />} />
        <Route path="/cart" render={() => <CartPage />} />
        <Route path="/checkout" render={() => <CheckoutPage />} />
        <Route path="/wishlist" render={() => <WishlistPage />} />
        <Route path="/account/:pageId" render={() => <AccountPage />} />
        <Route path="/account" render={() => <AccountPage />} />
        <Route
          path={'/filter:filter'}
          exact
          render={() => <CategoryPage />}
          key={'/filter'}
        />
        <Route
          path={'/filter:filter/page-:page'}
          exact
          render={() => <CategoryPage />}
          key={'/filter/page'}
        />
        {/* Sales page */}
        <Route exact path="/sales" render={() => <CategoryPage />} />
        <Route exact path="/sales/page-:page" render={() => <CategoryPage />} />
        <Route
          exact
          path="/sales/filter:filter"
          render={() => <CategoryPage />}
        />
        <Route
          exact
          path="/sales/filter:filter/page-:page"
          render={() => <CategoryPage />}
        />
        {/* Categories pages */}
        {routes.map((r) => (
          <Route path={r} exact render={() => <CategoryPage />} key={r} />
        ))}
        {/* Filtering pages */}
        {routes.map((r) => (
          <Route
            path={r + '/filter:filter'}
            exact
            render={() => <CategoryPage />}
            key={r + '/filter'}
          />
        ))}
        {/* Filtering + Paginations pages */}
        {routes.map((r) => (
          <Route
            path={r + '/filter:filter/page-:page'}
            exact
            render={() => <CategoryPage />}
            key={r + '/filter/page'}
          />
        ))}
        {/* Paginations pages */}
        {routes.map((r) => (
          <Route
            path={r + '/page-:page'}
            exact
            render={() => <CategoryPage />}
            key={r + '/page'}
          />
        ))}
        {/* Products pages */}
        {routes.map((r) => (
          <Route
            path={r + '/:slug'}
            exact
            render={() => <ProductPage />}
            key={r + '/slug'}
          />
        ))}
        <Route path="/terms" render={() => <ContentPage />} />
        <Route path="/privacy" render={() => <ContentPage />} />
        <Route path="/payments" render={() => <ContentPage />} />
        <Route path="/returns" render={() => <ContentPage />} />
        <Route path="/shipment" render={() => <ContentPage />} />
        <Route render={() => <Result status="404" title="404" />} />;
      </Switch>
    </Suspense>
  );
};

export default AppRouter;
