import { useContext, useEffect } from 'react';
import CommonStore from './store/CommonStore';
import './App.less';
import { Provider } from 'mobx-react';
import { ConfigProvider } from 'antd';
import { BrowserRouter } from 'react-router-dom';
import MainLayout from './components/MainLayout';

import en from 'antd/lib/locale/en_GB';
import ru from 'antd/lib/locale/ru_RU';
import he from 'antd/lib/locale/he_IL';
import { useTranslation } from 'react-i18next';
import { Locale } from 'antd/lib/locale-provider';

const allLangs: {
  [key: string]: { locale: Locale; direction: 'ltr' | 'rtl' };
} = {
  en: { locale: en, direction: 'ltr' },
  ru: { locale: ru, direction: 'ltr' },
  he: { locale: he, direction: 'rtl' },
};

const App = () => {
  //
  const storeCommon = useContext(CommonStore);

  //
  const { i18n } = useTranslation();

  //
  useEffect(() => {
    localStorage.setItem('need_address', new Date().getTime().toString());
  }, []);

  return (
    <Provider storeCommon={storeCommon}>
      <BrowserRouter>
        <ConfigProvider
          locale={allLangs[i18n.language]?.locale || he}
          direction={allLangs[i18n.language]?.direction || 'rtl'}
        >
          <MainLayout />
        </ConfigProvider>
      </BrowserRouter>
    </Provider>
  );
};

export default App;
