import Icon from '@ant-design/icons';

const chevronBottom = () => (
  <svg width='25' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m17.09 8.295-4.59 4.58-4.59-4.58-1.41 1.41 6 6 6-6-1.41-1.41Z'
      fill='#000'
      opacity='.52'
    />
  </svg>
);

const chevronLeft = () => (
  <svg width='30' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clipPath='url(#a)'>
      <path
        d='m13.535 15 6.188-6.188-1.768-1.767L10 15l7.955 7.955 1.768-1.768L13.535 15Z'
        fill='#222'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' transform='matrix(-1 0 0 1 30 0)' d='M0 0h30v30H0z' />
      </clipPath>
    </defs>
  </svg>
);

const chevronRight = () => (
  <svg width='30' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <g clip-path='url(#a)'>
      <path
        d='m16.465 15-6.188 6.188 1.768 1.767L20 15l-7.955-7.955-1.768 1.768L16.465 15Z'
        fill='#222'
      />
    </g>
    <defs>
      <clipPath id='a'>
        <path fill='#fff' transform='matrix(1 0 0 -1 0 30)' d='M0 0h30v30H0z' />
      </clipPath>
    </defs>
  </svg>
);

const chevronBigLeft = () => (
  <svg width='49' height='49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m22.406 24.321 9.9-9.9-2.828-2.828L16.75 24.321 29.478 37.05l2.828-2.828-9.9-9.9Z'
      fill='#222'
      opacity='.5'
    />
  </svg>
);

const chevronBigRight = () => (
  <svg width='49' height='49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m26.594 24.321-9.9 9.9 2.828 2.828L32.25 24.321 19.522 11.593l-2.828 2.828 9.9 9.9Z'
      fill='#222'
      opacity='.5'
    />
  </svg>
);

const chevronBigBottom = () => (
  <svg width='29' height='30' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m20.5 11.771-6 6-6-6'
      stroke='#000'
      strokeWidth='2'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const chevronBigTop = () => (
  <svg width='49' height='49' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='m24.32 22.406 9.9 9.9 2.829-2.828L24.32 16.75 11.593 29.478l2.828 2.828 9.9-9.9Z'
      fill='#fff'
    />
  </svg>
);

const filterIcon = () => (
  <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path d='M10 18h4v-2h-4v2ZM3 6v2h18V6H3Zm3 7h12v-2H6v2Z' fill='#fff' />
  </svg>
);

const burgerIcon = () => (
  <svg width='24' height='24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M3 12h18M3 6h18M3 18h18'
      stroke='#000'
      strokeWidth='1.6'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

const closeIconWhite = () => (
  <svg
    width='30'
    height='30'
    viewBox='0 0 30 30'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M29.5846 3.354L26.6471 0.416504L15.0013 12.0623L3.35547 0.416504L0.417969 3.354L12.0638 14.9998L0.417969 26.6457L3.35547 29.5832L15.0013 17.9373L26.6471 29.5832L29.5846 26.6457L17.9388 14.9998L29.5846 3.354Z'
      fill='white'
    />
  </svg>
);

const closeIconBlack = () => (
  <svg
    width='20'
    height='20'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M23.6654 2.6835L21.3154 0.333496L11.9987 9.65016L2.68203 0.333496L0.332031 2.6835L9.6487 12.0002L0.332031 21.3168L2.68203 23.6668L11.9987 14.3502L21.3154 23.6668L23.6654 21.3168L14.3487 12.0002L23.6654 2.6835Z'
      fill='#181818'
    />
  </svg>
);

const locationIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    fill='#000000'
    height='24px'
    width='24px'
    version='1.1'
    viewBox='-30 -30 440 440'>
    <path d='M184.333,0C102.01,0,35.036,66.974,35.036,149.297c0,33.969,11.132,65.96,32.193,92.515     c27.27,34.383,106.572,116.021,109.934,119.479l7.169,7.375l7.17-7.374c3.364-3.46,82.69-85.116,109.964-119.51     c21.042-26.534,32.164-58.514,32.164-92.485C333.63,66.974,266.656,0,184.333,0z M285.795,229.355     c-21.956,27.687-80.92,89.278-101.462,110.581c-20.54-21.302-79.483-82.875-101.434-110.552     c-18.228-22.984-27.863-50.677-27.863-80.087C55.036,78.002,113.038,20,184.333,20c71.294,0,129.297,58.002,129.296,129.297     C313.629,178.709,304.004,206.393,285.795,229.355z' />
    <path d='M184.333,59.265c-48.73,0-88.374,39.644-88.374,88.374c0,48.73,39.645,88.374,88.374,88.374s88.374-39.645,88.374-88.374     S233.063,59.265,184.333,59.265z M184.333,216.013c-37.702,0-68.374-30.673-68.374-68.374c0-37.702,30.673-68.374,68.374-68.374     s68.373,30.673,68.374,68.374C252.707,185.341,222.035,216.013,184.333,216.013z' />
  </svg>
);

const closeIcon = () => (
  <svg
    width='24'
    height='24'
    viewBox='0 0 24 24'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z'
      fill='#181818'
    />
  </svg>
);

const editIcon = () => (
  <svg
    width='31'
    height='20'
    viewBox='0 0 31 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M19.598 4.35006L15.7531 0.504336C15.5933 0.344446 15.4035 0.217611 15.1947 0.131075C14.9858 0.0445398 14.7619 0 14.5359 0C14.3098 0 14.0859 0.0445398 13.8771 0.131075C13.6682 0.217611 13.4785 0.344446 13.3187 0.504336L2.70741 11.1164C2.547 11.2758 2.41983 11.4654 2.33327 11.6743C2.24671 11.8832 2.20247 12.1071 2.20313 12.3332V16.179C2.20313 16.6354 2.38446 17.0732 2.70722 17.3959C3.02998 17.7187 3.46774 17.9 3.9242 17.9H7.76993C7.99602 17.9006 8.21999 17.8564 8.42886 17.7698C8.63772 17.6833 8.82734 17.5561 8.98672 17.3958L19.598 6.78365C19.9206 6.46091 20.1019 6.02322 20.1019 5.56686C20.1019 5.11049 19.9206 4.67281 19.598 4.35006ZM7.6245 15.8348H4.26841V12.4787L11.4969 5.25018L14.853 8.60627L7.6245 15.8348ZM16.3159 7.14336L12.9598 3.78727L14.538 2.20905L17.8941 5.56514L16.3159 7.14336Z'
      fill='black'
    />
    <path
      d='M1 19.147H30'
      stroke='black'
      strokeWidth='1.2908'
      strokeLinecap='round'
    />
  </svg>
);
const dateIcon = () => (
  <svg
    width='18'
    height='20'
    viewBox='0 0 18 20'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M2 20C1.45 20 0.979002 19.804 0.587002 19.412C0.195002 19.02 -0.000664969 18.5493 1.69779e-06 18V4C1.69779e-06 3.45 0.196002 2.979 0.588002 2.587C0.980002 2.195 1.45067 1.99933 2 2H3V0.975C3 0.691667 3.096 0.458333 3.288 0.275C3.48 0.0916668 3.71734 0 4 0C4.28334 0 4.521 0.0960001 4.713 0.288C4.905 0.48 5.00067 0.717333 5 1V2H13V0.975C13 0.691667 13.096 0.458333 13.288 0.275C13.48 0.0916668 13.7173 0 14 0C14.2833 0 14.521 0.0960001 14.713 0.288C14.905 0.48 15.0007 0.717333 15 1V2H16C16.55 2 17.021 2.196 17.413 2.588C17.805 2.98 18.0007 3.45067 18 4V18C18 18.55 17.804 19.021 17.412 19.413C17.02 19.805 16.5493 20.0007 16 20H2ZM2 18H16V8H2V18ZM2 6H16V4H2V6Z'
      fill='#D9D9D9'
    />
  </svg>
);

const deleteIcon = () => (
  <svg
    width='19'
    height='21'
    viewBox='0 0 19 21'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.36783 2.46416L6.60467 3.60488H12.4287L11.6656 2.46416C11.6053 2.3758 11.5049 2.31957 11.3965 2.31957H7.63292C7.52447 2.31957 7.42406 2.37178 7.36381 2.46416H7.36783ZM13.2722 1.39575L14.7463 3.60488H17.5499C18.0841 3.60488 18.5139 4.03465 18.5139 4.56886C18.5139 5.10307 18.0841 5.53284 17.5499 5.53284H17.2286V17.7433C17.2286 19.5186 15.7906 20.9566 14.0153 20.9566H5.01812C3.24278 20.9566 1.80484 19.5186 1.80484 17.7433V5.53284H1.48351C0.949307 5.53284 0.519531 5.10307 0.519531 4.56886C0.519531 4.03465 0.949307 3.60488 1.48351 3.60488H4.2871L5.76119 1.39173C6.17891 0.769161 6.88182 0.391602 7.63292 0.391602H11.3965C12.1476 0.391602 12.8505 0.769161 13.2682 1.39173L13.2722 1.39575ZM3.73281 5.53284V17.7433C3.73281 18.4542 4.30718 19.0286 5.01812 19.0286H14.0153C14.7262 19.0286 15.3006 18.4542 15.3006 17.7433V5.53284H3.73281ZM6.94608 8.10346V16.458C6.94608 16.8114 6.65689 17.1006 6.30343 17.1006C5.94997 17.1006 5.66077 16.8114 5.66077 16.458V8.10346C5.66077 7.75 5.94997 7.46081 6.30343 7.46081C6.65689 7.46081 6.94608 7.75 6.94608 8.10346ZM10.1594 8.10346V16.458C10.1594 16.8114 9.87016 17.1006 9.5167 17.1006C9.16324 17.1006 8.87405 16.8114 8.87405 16.458V8.10346C8.87405 7.75 9.16324 7.46081 9.5167 7.46081C9.87016 7.46081 10.1594 7.75 10.1594 8.10346ZM13.3726 8.10346V16.458C13.3726 16.8114 13.0834 17.1006 12.73 17.1006C12.3765 17.1006 12.0873 16.8114 12.0873 16.458V8.10346C12.0873 7.75 12.3765 7.46081 12.73 7.46081C13.0834 7.46081 13.3726 7.75 13.3726 8.10346Z'
      fill='#2B2929'
    />
  </svg>
);

//
const tabArrowRightIcon = () => (
  <svg
    width='9'
    height='16'
    viewBox='0 0 9 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M8.11726 8.70711C8.50779 8.31658 8.50779 7.68342 8.11726 7.29289L1.7533 0.928932C1.36278 0.538408 0.729613 0.538408 0.339088 0.928932C-0.0514359 1.31946 -0.0514359 1.95262 0.339088 2.34315L5.99594 8L0.339088 13.6569C-0.0514359 14.0474 -0.0514359 14.6805 0.339088 15.0711C0.729613 15.4616 1.36278 15.4616 1.7533 15.0711L8.11726 8.70711ZM6.41016 9H7.41016V7H6.41016V9Z'
      fill='#019BE1'
    />
  </svg>
);
//
const tabArrowLeftIcon = () => (
  <svg
    width='9'
    height='16'
    viewBox='0 0 9 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M0.292893 7.29289C-0.0976311 7.68342 -0.0976311 8.31658 0.292893 8.70711L6.65685 15.0711C7.04738 15.4616 7.68054 15.4616 8.07107 15.0711C8.46159 14.6805 8.46159 14.0474 8.07107 13.6569L2.41421 8L8.07107 2.34315C8.46159 1.95262 8.46159 1.31946 8.07107 0.928932C7.68054 0.538408 7.04738 0.538408 6.65685 0.928932L0.292893 7.29289ZM2 7H1V9H2V7Z'
      fill='#019BE1'
    />
  </svg>
);

//
const BurgerIcon = (props: any) => <Icon component={burgerIcon} {...props} />;
const TabArrowRightIcon = (props: any) => (
  <Icon component={tabArrowRightIcon} {...props} />
);
const TabArrowLeftIcon = (props: any) => (
  <Icon component={tabArrowLeftIcon} {...props} />
);
const DeleteIcon = (props: any) => <Icon component={deleteIcon} {...props} />;
const EditIcon = (props: any) => <Icon component={editIcon} {...props} />;
const DateIcon = (props: any) => <Icon component={dateIcon} {...props} />;
const ChevronBottom = (props: any) => (
  <Icon component={chevronBottom} {...props} />
);
const CloseIconWhite = (props: any) => (
  <Icon component={closeIconWhite} {...props} />
);
const CloseIconBlack = (props: any) => (
  <Icon component={closeIconBlack} {...props} />
);
const LocationIcon = (props: any) => (
  <Icon component={locationIcon} {...props} />
);
const CloseIcon = (props: any) => <Icon component={closeIcon} {...props} />;
const FilterIcon = (props: any) => <Icon component={filterIcon} {...props} />;
const ChevronBigTop = (props: any) => (
  <Icon component={chevronBigTop} {...props} />
);
const ChevronBigBottom = (props: any) => (
  <Icon component={chevronBigBottom} {...props} />
);
const ChevronBigRight = (props: any) => (
  <Icon component={chevronBigRight} {...props} />
);
const ChevronBigLeft = (props: any) => (
  <Icon component={chevronBigLeft} {...props} />
);
const ChevronRight = (props: any) => (
  <Icon component={chevronRight} {...props} />
);
const ChevronLeft = (props: any) => <Icon component={chevronLeft} {...props} />;

//
export {
  ChevronBottom,
  FilterIcon,
  ChevronLeft,
  ChevronRight,
  ChevronBigRight,
  ChevronBigLeft,
  ChevronBigBottom,
  ChevronBigTop,
  BurgerIcon,
  CloseIconWhite,
  CloseIconBlack,
  LocationIcon,
  CloseIcon,
  EditIcon,
  DateIcon,
  DeleteIcon,
  TabArrowRightIcon,
  TabArrowLeftIcon,
};
