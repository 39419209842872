import { Form, Input, Button, Typography, InputNumber } from 'antd';
import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../api/api';
import CommonStore from '../../store/CommonStore';
import { LoginAnswer, LoginStepType } from '../../types';
import { parsePhoneNumber } from 'libphonenumber-js';
import OtpInput from '../../UI/OtpInput';

interface ValidationProps {
  setCode: (code: number) => void;
  phone: string;
  setStep: (step: LoginStepType) => void;
  loginCallback: () => void;
}

const Validation = ({
  phone,
  setCode,
  setStep,
  loginCallback,
}: ValidationProps) => {
  //
  const { t } = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const [form] = Form.useForm();

  //
  const [loading, setLoading] = useState(false);

  //
  const onLoginFormSubmit = async (values: { phone: string; code: number }) => {
    setLoading(true);

    const response: LoginAnswer = await api('api/validate', 'POST', values);

    setLoading(false);

    if (response.error) {
      response.error.forEach((item) => {
        form.setFields([
          {
            name: item.field,
            errors: [item.message],
          },
        ]);
      });
    } else {
      if (response.user) {
        if (response.token) localStorage.setItem('token', response.token);
        storeCommon.setUser(response.user);

        form.resetFields();
        loginCallback();

        return;
      }

      setCode(values.code);
      // successCallback();
      setStep('reset');
    }
  };

  return (
    <Form
      name="code-validation"
      initialValues={{ phone: phone.startsWith('+') ? phone : `+${phone}` }}
      onFinish={onLoginFormSubmit}
      form={form}
      onValuesChange={(values: { code: string }) => {
        if (values.code && values.code.length === 4) {
          onLoginFormSubmit(form.getFieldsValue());
        }
      }}
    >
      <Typography.Title level={3}>{t('Validate phone')}</Typography.Title>
      <div className="ic-login__subtitle">
        {t('We sent confirmation code to your phone. Phone number')}
        {': '}
        <span dir="ltr" onClick={() => setStep('login')}>
          {parsePhoneNumber(
            phone.startsWith('+') ? phone : `+${phone}`
          )?.formatNational()}
        </span>
      </div>
      <Form.Item
        name="phone"
        label={t('Phone')}
        rules={[{ required: true }]}
        hidden
        noStyle
      >
        <Input />
      </Form.Item>

      <Form.Item
        name="code"
        label={t('Validation code')}
        rules={[{ required: true }]}
        labelCol={{ hidden: true }}
        style={{
          maxWidth: 240,
          margin: '0 auto 30px',
        }}
      >
        {/* <InputNumber
          type='number'
          // min={0}
          // max={9999}
          controls={false}
          // prefix={<UserOutlined />}
          placeholder={t('Validation code')}
          style={{width: '100%'}}
        /> */}
        <OtpInput length={4} />
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit" block loading={loading}>
          {t('Check validation code')}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default Validation;
