export enum OrderStatuses {
  CLIENT_CANCELATION = -10,
  SUSTEM_ABORTED = -1,
  JUST_CREATED = 0,
  PRODUCT_RESERVATION = 5,
  TOTAL_PAID = 10,
  ORDER_PREPARATION = 20,
  WAITING_FOR_SENDING = 40,
  WAS_SEND = 50,
  CLIENT_RECIEVED = 60,
}

export enum Measurement {
  PTS = 0,
  KG = 1000,
  GR = 100,
}
