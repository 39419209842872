import {Badge, Button} from 'antd';
import classNames from 'classnames';
import {autorun} from 'mobx';
import {Observer} from 'mobx-react';
import {useContext, useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {setTimeout} from 'timers';
import CommonStore from '../../store/CommonStore';
import CartDropdown from '../CartDropdown';

interface CartButtonScrollProps {
  scroll: number;
}

const CartButtonScroll = ({scroll}: CartButtonScrollProps) => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const location = useLocation();
  const route = location.pathname.split('/')[1];
  //
  const [active, setActive] = useState(false);
  const [cartOpen, setCartOpen] = useState(false);
  //
  useEffect(() => {
    autorun(() => {
      if (storeCommon.cart.length) {
        setActive(true);
        setTimeout(() => {
          setActive(false);
        }, 1000);
      }
    });
  }, []);

  return (
    <>
      <Observer>
        {() => {
          if (storeCommon.cart.length === 0) {
            return <></>;
          }
          if (route === 'checkout' || route === 'cart') {
            return <></>;
          }
          return (
            <>
              {scroll >= 100 && (
                <>
                  <div
                    id='ic-cart__float'
                    className={classNames('ic-cart__float', {
                      active: active,
                      visible: Math.round(scroll) >= 150,
                    })}>
                    <Badge
                      className='ic-header__badge badge_cart'
                      count={storeCommon.cart.length}>
                      <Button
                        className='ic-cart__float-btn'
                        onClick={() =>
                          storeCommon.cart.length > 0
                            ? setCartOpen(o => !o)
                            : setCartOpen(false)
                        }
                      />
                    </Badge>
                  </div>
                </>
              )}
            </>
          );
        }}
      </Observer>
      <CartDropdown cartOpen={cartOpen} setCartOpen={setCartOpen} />
    </>
  );
};

export default CartButtonScroll;
