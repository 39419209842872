import {Button, Image, InputNumber} from 'antd';
import classNames from 'classnames';
import {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';
import CommonStore from '../../store/CommonStore';
import {CartProduct} from '../../types';
import {asCurrency} from '../../utils/formatter';
import noImage from '../../assets/img/no-photo-icon2.svg';
import {Measurement} from '../../types/enums';
import useScreenWidth from '../../hooks/useScreenWidth';
import {Observer} from 'mobx-react';
import {toJS} from 'mobx';

interface ProductCheckoutProps {
  cart: CartProduct;
  buttons?: boolean;
  cartPage?: boolean;
}

const ProductCheckout = ({
  cart,
  buttons = true,
  cartPage = false,
}: ProductCheckoutProps) => {
  //
  const storeCommon = useContext(CommonStore);
  //
  const width = useScreenWidth();
  //
  const {t, i18n} = useTranslation();

  const link = storeCommon.getProductRoute(cart.product);

  return (
    <div className='ic-cart__product'>
      <div className='ic-cart__product-wrapper'>
        <Link to={link} className='ic-cart__product-image'>
          <Image
            className='ic-cart__product-image'
            src={
              cart.product.images?.[0]?.url /*.replace(
                 'inclick.local',
                 'inclick.shop',
               )*/ || noImage
            }
            preview={false}
          />
        </Link>
        <div className='ic-cart__product-info'>
          <Link to={link}>
            <div className='ic-cart__product-info__title'>
              {cart.product.title}
            </div>
            {/* <div className='ic-cart__product-info__desc'>
              {cart.product.short}
            </div> */}
          </Link>

          <div className='ic-cart__product-info__quantity'>
            <div className='ic-cart__product-info__quantity-title'>
              {`${t('Qty')}: ${!buttons ? cart.quantity : ''}`}
            </div>
            {buttons && (
              <>
                <div className='ic-quantity__control'>
                  <Button
                    type='text'
                    className='ic-quantity__down'
                    onClick={() => {
                      storeCommon.addToCart({
                        ...cart,
                        quantity: -(cart.product.price_unit === Measurement.KG
                          ? 0.1
                          : 1),
                      });
                    }}
                    disabled={
                      cart.quantity ===
                      (cart.product.min_to_order
                        ? cart.product.min_to_order
                        : cart.product.price_unit === Measurement.KG
                        ? 0.1
                        : 1)
                    }
                  />
                  <InputNumber
                    className='ic-quantity__input'
                    min={
                      cart.product.min_to_order
                        ? cart.product.min_to_order
                        : cart.product.price_unit === Measurement.KG
                        ? 0.1
                        : 1
                    }
                    step={cart.product.price_unit === Measurement.KG ? 0.1 : 1}
                    max={cart.product.subtract ? cart.product.stock : undefined}
                    size='large'
                    value={cart.quantity}
                    onChange={value => {
                      if (!value) {
                        return;
                      }
                      storeCommon.changeCartQuantity(
                        cart,
                        value || 0,
                        cart.product.subtract ? cart.product.stock || 0 : 0,
                      );
                    }}
                    controls={false}
                  />
                  <Button
                    type='text'
                    className='ic-quantity__up'
                    onClick={() =>
                      storeCommon.addToCart(
                        {
                          ...cart,
                          quantity:
                            cart.product.price_unit === Measurement.KG
                              ? 0.1
                              : 1,
                        },
                        cart.product.subtract ? cart.product.stock || 0 : 0,
                      )
                    }
                    disabled={
                      cart.product.subtract
                        ? cart.quantity < cart.product.stock
                          ? false
                          : true
                        : false
                    }
                  />
                </div>
              </>
            )}
            {cart.product.price_unit === Measurement.KG && (
              <span className=''>{t('KG')}</span>
            )}

            <div
              className={classNames('ic-category__products-price', {
                btnShow: !buttons,
              })}
              style={{marginInlineStart: 'auto', alignItems: 'flex-end'}}>
              {/* {t("Total")}:&nbsp;&nbsp;&nbsp; */}
              <span
                className={classNames('ic-category__products__price_current', {
                  'ic-category__products-price_new': cart.total_discounted,
                })}>
                {asCurrency(
                  cart.total_discounted || cart.total,
                  i18n.language,
                  'ILS',
                )}
              </span>
              {cart.total_discounted && (
                <span className='ic-category__products-price_old'>
                  {asCurrency(cart.total, i18n.language, 'ILS')}
                </span>
              )}
              {/* &nbsp; &nbsp;
              {cart.product.price_unit === Measurement.KG && (
                <div>{`/ ${t("per KG")}`}</div>
              )} */}
            </div>
          </div>

          <div className='ic-cart__product-buttons-wrapper'>
            <div className='ic-cart__product-buttons'>
              {buttons && (
                <Observer>
                  {() => (
                    <Button
                      type='text'
                      className={classNames('ic-cart__product-wishlist', {
                        active:
                          storeCommon.wishlist.findIndex(
                            w => w === cart.product._id,
                          ) >= 0,
                      })}
                      onClick={e => {
                        e.preventDefault();
                        e.stopPropagation();
                        storeCommon.toggleWishlist(cart.product._id);
                      }}>
                      {t('Wishlist')}
                    </Button>
                  )}
                </Observer>
              )}
              {buttons && (
                <Button
                  type='text'
                  className='ic-cart__product-remove'
                  onClick={() => storeCommon.removeFromCart(cart)}>
                  {t('Remove')}
                </Button>
              )}
            </div>
            {!!cart.variant?.length && (
              <div className='ic-cart__product-variant'>
                {cart.variant?.map(item => {
                  const option = cart.product.options.find(
                    opt => opt._id === item.option,
                  );
                  const variant = cart.product.options
                    .find(opt => opt._id === item.option)
                    ?.values?.find(val => val._id === item.value);

                  return (
                    <div className='ic-cart__product-variant-wrapper'>
                      <div className='ic-cart__product-info__quantity-title'>
                        {`${option?.title}: `}
                      </div>
                      <div className='ic-cart__product-info__quantity-title'>
                        {variant?.title}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductCheckout;
