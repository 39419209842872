import {notification, Divider, Modal, Typography} from 'antd';
import {useContext, useState} from 'react';
import FacebookLogin, {
  ReactFacebookFailureResponse,
  ReactFacebookLoginInfo,
} from 'react-facebook-login';
import GoogleLogin, {
  GoogleLoginResponse,
  GoogleLoginResponseOffline,
} from 'react-google-login';
import {useTranslation} from 'react-i18next';
import api from '../../api/api';
import CommonStore from '../../store/CommonStore';
import {LoginAnswer, LoginStepType} from '../../types';
import Login from './Login';
import Validation from './Validation';
import {CloseIcon} from '../CustomIcons';

const {Title} = Typography;

type SocialData = {
  type: 'google' | 'facebook';
  email: string | null;
  user_id: string;
  name: string;
  avatar: string | null;
  access_token: string;
};

interface UserLoginFormProps {
  visible: boolean;
  setVisible: (value: boolean) => void;
  loginCallback: () => void;
}

const UserLoginForm = ({
  visible,
  setVisible,
  loginCallback,
}: UserLoginFormProps) => {
  //
  const {t} = useTranslation();

  //
  const storeCommon = useContext(CommonStore);

  //
  const [step, setStep] = useState<LoginStepType>('login');

  //
  const [activationPhone, setActivationPhone] = useState('');
  const [validationCode, setValidationCode] = useState(0);

  const responseFacebook = (
    response: ReactFacebookLoginInfo | ReactFacebookFailureResponse,
  ) => {
    if ((response as ReactFacebookFailureResponse).status) {
      notification.error({
        message: (response as ReactFacebookFailureResponse).status,
      });
      return;
    }
    onSocialLogin({
      type: 'facebook',
      email: (response as ReactFacebookLoginInfo).email || null,
      user_id: (response as ReactFacebookLoginInfo).userID,
      name: (response as ReactFacebookLoginInfo).name || '',
      avatar: (response as ReactFacebookLoginInfo).picture?.data.url || null,
      access_token: (response as ReactFacebookLoginInfo).accessToken,
    });
  };

  const responseGoogle = (
    response: GoogleLoginResponse | GoogleLoginResponseOffline,
  ) => {
    if ((response as GoogleLoginResponseOffline).code) {
      notification.error({
        message: (response as GoogleLoginResponseOffline).code,
      });
      return;
    }
    onSocialLogin({
      type: 'google',
      email: (response as GoogleLoginResponse).profileObj.email || null,
      user_id: (response as GoogleLoginResponse).googleId,
      name: (response as GoogleLoginResponse).profileObj.name || '',
      avatar: (response as GoogleLoginResponse).profileObj.imageUrl || null,
      access_token: (response as GoogleLoginResponse).accessToken,
    });
  };

  //
  const onSocialLogin = async (values: SocialData) => {
    const response: LoginAnswer = await api('api/login-social', 'POST', values);
    checkLoginResponse(response);
  };

  //
  const checkLoginResponse = (response: LoginAnswer) => {
    if (response.error) {
    } else {
      if (response.user) storeCommon.setUser(response.user);
      if (response.token) localStorage.setItem('token', response.token);

      loginCallback();
    }
  };

  return (
    <Modal
      className='ic-template ic-login-modal'
      open={visible}
      closable
      onCancel={() => {
        setStep('login');
        setVisible(false);
      }}
      closeIcon={<CloseIcon />}
      footer={false}>
      {step === 'login' && (
        <>
          <Title level={2} className='ic-login__title'>
            {t('Login')}
          </Title>
          <div className='ic-login__subtitle'>{t('Login to continue')}</div>
        </>
      )}

      {step === 'signup' && (
        <>
          <Title level={2} className='ic-login__title'>
            {t('Registration')}
          </Title>
        </>
      )}

      {!!0 && (step === 'login' || step === 'signup') && (
        <>
          <div className='ic-login__social'>
            <FacebookLogin
              appId='322450022684393'
              autoLoad={false}
              fields='name,email,picture'
              callback={responseFacebook}
              cssClass='ic-login__facebook'
              icon={false}
              textButton={t('Login with Facebook')}
            />

            <GoogleLogin
              clientId='232673908874-7rsv0kljgbr6ioblaslpjsfgaj8bl0qp.apps.googleusercontent.com'
              onSuccess={responseGoogle}
              cookiePolicy={'single_host_origin'}
              render={renderProps => (
                <button
                  className='ic-login__google'
                  onClick={renderProps.onClick}
                  disabled={renderProps.disabled}>
                  {t('Login with Google')}
                </button>
              )}
            />
          </div>
          <Divider>{t('OR')}</Divider>
        </>
      )}

      {step === 'login' && (
        <Login
          // loginCallback={loginCallback}
          setStep={setStep}
          setPhone={setActivationPhone}
          phone={activationPhone}
          onClose={() => {
            setStep('login');
            setVisible(false);
          }}
        />
      )}

      {/* {step === "signup" && (
        <SingUp signUpCallback={loginCallback} setStep={setStep} />
      )} */}

      {step === 'validation' && (
        <Validation
          setStep={setStep}
          loginCallback={loginCallback}
          setCode={setValidationCode}
          phone={activationPhone}
        />
      )}

      {/* {step === "forgot" && (
        <Forgot setStep={setStep} setEmail={setActivationEmail} />
      )} */}

      {/* {step === "reset" && (
        <Reset
          successCallback={() => {
            setActivationEmail("");
            setValidationCode(0);
            setStep("login");

            notification.success({ message: "Password changed successful" });
          }}
          email={activationEmail}
          code={validationCode}
        />
      )} */}
    </Modal>
  );
};

export default UserLoginForm;
